<template>
  <div class="edit_options">
    <h4 class="sub-title">{{ $t("yadoEdit.facilityTag.title") }}</h4>

    <!-- select facility tag  -->
    <div class="edit_form">
      <CRow 
        class="border-bottom"
        v-for="(tagsList,i) in tagsLists"
        :key="i"
        >
        <CCol md="3">
        <strong>{{ tagsList.typeName }}</strong></CCol
        >
        <CCol>
          <div class="form-inline">
            <CInputCheckbox
              class="mx-1"
              v-for="(tag, n) in tagsList.tags"
              :key="n + tag"
              :label="tag.name"
              :value="tag.name"
              custom
              :checked.sync="tag.enabled"
            />
          </div>
        </CCol>
      </CRow>
      <div class="tab_footer">
        <CButton class="mt-3" color="success" @click="reqPostFacilityTag()"
          ><i class="cil-save"></i> {{ $t("common.update") }}</CButton
        >
      </div>
      <!-- .tab_footer end  -->

    </div>
    <!-- edit_form end -->

    <!-- .tab_footer end  -->
    <CModal
      color="success"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="successModal"
    >
      {{ $t("commonMessage.updateSuccessText") }}
      <template #header>
        {{ $t("commonMessage.updateSuccessHeader") }}
      </template>
      <template #footer>
        <CButton @click="successModal = false" color="secondary">
          <i class="icon cil-x"></i> {{ $t("common.close") }}
        </CButton>
      </template>
    </CModal>
    <CModal
      color="danger"
      closeOnBackdrop
      :centered="true"
      :show.sync="errorModal"
    >
      <p>{{ errorMessage }}</p>
      <template #header>
        <h4 class="modal-title">{{ $t("common.error") }}</h4>
      </template>
      <template #footer>
        <CButton @click="errorModal = false" color="secondary">
          <i class="icon cil-x"></i> {{ $t("common.close") }}
        </CButton>
      </template>
    </CModal>
  </div>
  <!-- edit_options -->
</template>

<script>
// import i18n from "../i18n";
import TAG_TYPE from "@/mixins/property";
export default {
  name: "FacilityTag",
  /**タイプ */
  mixins: [TAG_TYPE],
  data() {
    return {
      successModal: false,
      errorModal: false,
      amenityModal: false,
      featureModal: false,
      /**宿が設定している宿タグリスト */
      ownTags: [],
      errorMessage: "",
      reload: 1,
      /**宿探しに登録された全てのタグ情報 */
      tagsLists: [],
      /**レスポンス */
      tags:[],
      
    };
  },
  computed: {
    /**宿ID取得 */
    uid() {
      return this.$store.state.uniqueId;
    }
  },
  methods: {

    /**全てのタグ情報取得 */
    reqGetAllTags() {
      // 初期化
      this.tagsLists = [];
      this.ownTags = [];
      this.tags = [];

      const callback = (a) => {
        this.tags = a.tags;
        this.reqGetFacilityTag(this.tags);
      };

      this.reqGetTagAll(callback);
    },
    
    /**宿のタグ情報取得 */
    reqGetFacilityTag(allTags) {
      const callback = (a) => {
        this.ownTags = a.tags;
        // 全てのタグの中で、リレーションしているものだけチェック入れる
        this.tags = allTags;
        this.addEnabled(this.tags);
        this.sortTypeTags(this.tags);
      };

      const errorCallback = (err) => {
        console.log(JSON.stringify(err));

        // 関連づいたタグが無い場合でも画面表示の為に処理を行う
        if(JSON.stringify(err).includes("400")){
          this.addEnabled(this.tags);
          this.sortTypeTags(this.tags);
          return;
        }
        // 400エラー以外の時はエラーモーダル表示
        this.errorMessage = err;
        this.errorModal = true;
      };

      this.reqGetHotelTagAll(callback,errorCallback);
    },

    /**
     * add  enabled property
     */
     addEnabled(allTags){
      console.log("addEnable!");
      console.log(allTags);
      allTags.forEach((a) => {
         // チェックボックス用にenabled追加
         this.ownTags.forEach((b) => {
           if (a.id === b.id) {
             a.enabled = true;
           }
         });
      });
    },
    /**
     * Sort tags by type
     */
    sortTypeTags(allTags){
      console.log(this.TAG_TYPE)
      this.TAG_TYPE.forEach((a) => {
        const type = a.value;
        const label = a.label
        const tags = allTags.filter(b => b.type == type);
        // this.tagsLists.push(tags);
        this.tagsLists.push({ typeName: label, tags: tags });
      });
      // console.log("all tagsLists"+JSON.stringify(this.tagsLists))
    },


     /**宿のタグ情報更新 */
    reqPostFacilityTag() {
      // 表示している中でenabledがtrueのタグ情報だけリクエストする
      const body = [];
      this.tagsLists.forEach((a) => {
        const requests = a.tags.filter((b)=> b.enabled);
        body.push(...requests);
      });

      const callback = () => {
        this.reqGetAllTags();
        this.successModal = true;
      };

      const errorCallback = (err) => {
        this.errorMessage = err;
        this.errorModal = true;
      };

      this.reqPostHotelTag(body,callback,errorCallback);
    },

  },
  beforeMount() {
    this.reqGetAllTags();
  },
};
</script>
<style lang="scss">
.edit_options {
  .edit_form {
    .row {
      .col,
      .col-md-3 {
        padding: 0.5rem 0;
      }
    }
  }
}
.exchange-mark {
  position: relative;
  i {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>