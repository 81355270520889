<template>
  <div class="edit_basic_options">
    <h4 class="sub-title">{{$t('facilityEditPage.bathformation')}}</h4>

    <div
      class="edit_form"
      v-if="isLoad"
    >

      <!-- <code>{{allPropertiesView}}</code> -->
      <template v-for="property in allPropertiesView">
        <CRow
          class="border-bottom"
          :key="property.id"
          v-if="property.id !== 36"
        >
          <CCol md="3"><strong class="form_lable">{{ property.name }}</strong>
          </CCol>
          <CCol>
            <!-- <ServiceOptionModal
            :propertyItem="property"
            @property-status="property.status = $event"
            @property-description="property.description = $event"
            :key="property.status"
          /> -->
            <template v-if="[19].includes(property.id)">
              <table class="person-input">
                <tr>
                  <th>大浴場</th>
                  <td>男</td>
                  <td>
                    <CInput
                      type="number"
                      class="w-1"
                      :value.sync="bath.big.male"
                    />
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <td>女</td>
                  <td>
                    <CInput
                      type="number"
                      class="w-1"
                      :value.sync="bath.big.female"
                    />
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <td>混浴</td>
                  <td>
                    <CInput
                      type="number"
                      class="w-1"
                      :value.sync="bath.big.mixed"
                    />
                  </td>
                </tr>
                <tr>
                  <th>露天風呂</th>
                  <td>男</td>
                  <td>
                    <CInput
                      type="number"
                      class="w-1"
                      :value.sync="bath.outdoor.male"
                    />
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <td>女</td>
                  <td>
                    <CInput
                      type="number"
                      class="w-1"
                      :value.sync="bath.outdoor.female"
                    />
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <td>混浴</td>
                  <td>
                    <CInput
                      type="number"
                      class="w-1"
                      :value.sync="bath.outdoor.mixed"
                    />
                  </td>
                </tr>                
                <tr>
                  <th>貸切風呂</th>
                  <td></td>
                  <td>
                    <CInput
                      type="number"
                      class="w-1"
                      :value.sync="bath.reserved"
                    />
                  </td>
                </tr>
              </table>
              <!-- お風呂 -->
              個数を入力してください。
            </template>
            <template v-else>
              <CTextarea
                rows="3"
                v-model="property.description"
              />
            </template>
            <template v-if="[44].includes(property.id)">
              <!-- お風呂について -->
              お風呂の魅力紹介は「ピックアップ」へご記入下さい。<br />
              例）大浴場は男女入れ替え制となります。<br />
              例）サウナは土曜日のみご利用頂けます。<br />
              例）入浴時間：6:00～9:00／16:00～23:00
            </template>
            <template v-if="[34].includes(property.id)">
              <!-- 日帰り入浴 -->
              日帰り入浴を行っていない場合は非表示(未記入)を推奨します。<br />
              例）大人1,000円／小人500円
            </template>
          </CCol>
        </CRow>
      </template>
      <!-- onsen options  -->
      <div class="tab_footer">
        <CButton
          class="mt-3"
          color="success"
          @click="reqPostProperties()"
        ><i class="cil-save"></i> {{
        $t("common.update")
      }}</CButton>
      </div>
      <!-- .tab_footer end  -->
    </div>
    <!-- edit_form end -->

    <CModal
      color="success"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="successModal"
    >
      {{ $t("commonMessage.updateSuccessText") }}
      <template #header> {{ $t("commonMessage.updateSuccessHeader") }} </template>
      <template #footer>
        <CButton
          @click="successModal = false"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{$t('common.close')}}
        </CButton>
      </template>
    </CModal>
    <CModal
      color="danger"
      closeOnBackdrop
      :centered="true"
      :show.sync="errorModal"
    >
      <p>{{ errorMessage }}</p>
      <template #header>
        <h4 class="modal-title">{{$t('common.error')}}</h4>
      </template>
      <template #footer>
        <CButton
          @click="errorModal = false"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{$t('common.close')}}
        </CButton>
      </template>
    </CModal>
  </div>
  <!-- edit_options -->
</template>

<script>
// import i18n from "../i18n";
// import ServiceOptionModal from "@/components/ServiceOptionModal";

import PROPERTYLIST from "@/mixins/property";

export default {
  mixins: [PROPERTYLIST],
  name: "EditBath",
  components: {
    // ServiceOptionModal,
  },
  data() {
    return {
      isLoad: false,
      successModal: false,
      errorModal: false,
      errorMessage: "",
      allProperties: [],
      allPropertiesView: [
        {
          id: "",
          name: "",
          description: "",
          status: "",
        },
      ],

      bath: {
        /** 大浴場 */
        big: {
          male: 0,
          female: 0,
          mixed: 0,
        },
        /** 露天風呂 */
        outdoor: {
          male: 0,
          female: 0,
          mixed: 0,
        },
        /** 貸切風呂 */
        reserved: 0,
      },
    };
  },
  computed: {
    /**宿ID取得 */
    uid() {
      return this.$store.state.uniqueId;
    },
  },
  methods: {
    /**全てのプロパティ取得 */
    reqGetAllProperties() {
      const callback = (a) => {
        this.allProperties = [];
        this.isLoad = true;
        const allList = a.properties;

        // only below selected properties are been shown
        const selectedList = [
          this.PROPERTYLIST.bath,
          this.PROPERTYLIST.aboutBath,
          this.PROPERTYLIST.hotSpringName,
          this.PROPERTYLIST.springAndEfficency,
          this.PROPERTYLIST.oneDayBath,
          this.PROPERTYLIST.bathingTime,
        ];

        allList.forEach((a) => {
          selectedList.forEach((l, k) => {
            if (a.id == l) {
              a.viewOrder = k;
              this.allProperties.push(a);
            }
          });
        });

        this.allProperties.sort((a, b) => a.viewOrder - b.viewOrder);
        this.reqGetAllSelectedProperties();
      };

      this.reqGetPropertyAll(callback);
    },

    /**宿のプロパティ取得 */
    reqGetAllSelectedProperties() {
      const callback = (a) => {
        const getNewD = a.properties;
        // match data with all data
        this.allProperties.forEach((e) => {
          e.description = "";
          getNewD.forEach((a) => {
            if (a.propertyId == e.id) {
              e.pId = a.propertyId;
              e.description = a.description;
              e.status = a.status;
            }
          });
        });
        this.allPropertiesView = this.allProperties;

        // お風呂
        this.bath = JSON.parse(
          this.allPropertiesView.find((e) => e.pId === 19).description
        );
        this.debugLog(this.bath);
      };

      this.reqGetHotelOptionAll(callback);
    },

    /**宿のプロパティ登録 */
    reqPostProperties() {
      // 特殊項目を対応
      this.allPropertiesView.forEach((a) => {
        switch (a.id) {
          case 19: // お風呂
            a.description = JSON.stringify(this.bath);
            break;
          default:
            break;
        }
      });

      const body = [];
      this.allPropertiesView.filter((e) => {
        if ((e.description && e.description.length >= 1) || e.pId) {
          body.push({
            propertyId: e.id,
            description: e.description,
            status: e.status ? e.status : 0,
            language: this.$i18n.locale,
          });
        }
      });

      const callback = () => {
        this.reqGetAllProperties();
        this.successModal = true;
      };

      const errorCallback = (err) => {
        this.errorMessage = err;
        this.errorModal = true;
      };

      this.reqPostHotelOption(body, callback, errorCallback);
    },
  },
  beforeMount() {
    this.reqGetAllProperties();
  },
};
</script>
<style lang="scss">
.form-group {
  margin: 0;
}
.edit_basic_options {
  .edit_form {
    .row {
      .col,
      .col-md-3 {
        padding: 0.5rem 0.4rem;
        display: inline-block;
      }
    }
    .list-group-item {
      .col-md-3,
      .col {
        padding: 0 0.4rem;
        display: inline-block;
      }
    }
  }
}
.person-input {
  width: 100%;
  max-width: 200px;
  margin-bottom: 3%;
  tr {
    // margin
    th {
      width: 50%;
    }
  }
}
</style>