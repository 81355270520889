<template>
  <div class="edit_options">
    <h4 class="sub-title">{{$t('facilityEditPage.serviceInformation')}}</h4>

    <!-- select amenity region -->
    <div class="edit_form">
      <CRow class="border-bottom">
        <CCol md="3"><strong>{{ $t("amenity&Featues.commonAmenities")}}</strong></CCol>
        <CCol>
          <div class="form-inline">
            <CInputCheckbox
              class="mx-1"
              v-for="(option, n) in getAmenityList"
              :key="n + option"
              :label="option.name"
              :value="option.name"
              custom
              :checked.sync="option.enabled"
            />
          </div>
        </CCol>
      </CRow>
      <!-- #end region select amenity   -->

      <!-- input about amenity region  -->
      <CRow
        class="border-bottom"
        v-if="aboutAmenity.name"
      >
        <CCol md="3"><strong>{{ aboutAmenity.name }}</strong></CCol>
        <CCol>
          <CTextarea
            class="text-dark"
            rows="3"
            v-model="aboutAmenity.description"
          />
          例）お部屋タイプにより、アメニティや備品が変わりますのでご注意ください。<br />
          例）ペットアメニティも用意がございます。
        </CCol>
      </CRow>
      <!-- #end region input about amenity   -->

      <!-- select feature  -->
      <CRow class="border-bottom">
        <CCol md="3"><strong>{{ $t("amenity&Featues.EquipmentFeatures")}}</strong></CCol>
        <CCol>
          <div class="form-inline">
            <CInputCheckbox
              class="mx-1"
              v-for="(option, n) in getFeaturesList"
              :key="n + option"
              :label="option.name"
              :value="option.name"
              custom
              :checked.sync="option.enabled"
            />
          </div>
        </CCol>
      </CRow>
      <!-- #end region select feature  -->

      <!-- input about feature region  -->
      <CRow class="border-bottom">
        <CCol md="3"><strong>{{ aboutFeatures.name}}</strong></CCol>
        <CCol>
          <CTextarea
            class="input-text-dark"
            rows="3"
            v-model="aboutFeatures.description"
          />
          例）カラオケルームは事前予約が必要です。（当日予約可）<br />
          例）外付けのシャワーがございます。海水浴からお帰りになられた時などお使いください。
        </CCol>
      </CRow>
      <!-- #end region input about feature   -->

      <CRow class="border-bottom">
        <CCol md="3"><strong>{{ othersProperty.name}}</strong></CCol>
        <CCol>
          <CTextarea
            class="input-text-dark"
            rows="3"
            v-model="othersProperty.description"
          />
        </CCol>
      </CRow>
      <div class="tab_footer">
        <CButton
          class="mt-3"
          color="success"
          @click="reqPostSelectedAmenities()"
        ><i class="cil-save"></i> {{ $t("common.update") }}</CButton>
      </div>
      <!-- selected: {{others}}
      Get:
      <pre><code>{{getFeaturesList}}</code></pre>
      allFeaturesList:
      <pre><code>{{getAmenityList}}</code></pre> -->
    </div>
    <!-- edit_form end -->

    <!-- .tab_footer end  -->
    <CModal
      color="success"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="successModal"
    >
      {{ $t("commonMessage.updateSuccessText") }}
      <template #header> {{ $t("commonMessage.updateSuccessHeader") }} </template>
      <template #footer>
        <CButton
          @click="successModal = false"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{$t('common.close')}}
        </CButton>
      </template>
    </CModal>
    <CModal
      color="danger"
      closeOnBackdrop
      :centered="true"
      :show.sync="errorModal"
    >
      <p>{{ errorMessage }}</p>
      <template #header>
        <h4 class="modal-title">{{$t('common.error')}}</h4>
      </template>
      <template #footer>
        <CButton
          @click="errorModal = false"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{$t('common.close')}}
        </CButton>
      </template>
    </CModal>
  </div>
  <!-- edit_options -->
</template>

<script>
// import i18n from "@/i18n";

import PROPERTYLIST from "@/mixins/property";

export default {
  mixins: [PROPERTYLIST],
  name: "AmenityFeatures",
  components: {},
  data() {
    return {
      successModal: false,
      errorModal: false,
      amenityModal: false,
      featureModal: false,
      getAmenityList: [],
      allAmenityList: [],
      getFeaturesList: [],
      allFeaturesList: [],
      allProperties: [],
      othersProperty: {},
      aboutAmenity: {},
      aboutFeatures: {},
      selectedAmenity: "",
      selectedFeature: "",
      others: "",
      errorMessage: "",
      reload: 1,
    };
  },
  computed: {
    /**宿ID取得 */
    uid() {
      return this.$store.state.uniqueId;
    }
  },
  methods: {

    /** 全てのアメニティ取得 */
    reqGetAllAmenities() {

      const callback = (a) => {
        const amenities = a.amenities;
        this.reqGetAllSelectedAmenities(amenities);
      }

      this.reqGetAmenityAll(callback);
    },

    /** 宿のアメニティ取得 */
    reqGetAllSelectedAmenities(data) {

      const callback = (a) => {
        this.getAmenityList = data;
        this.allAmenityList = a.features;

        this.getAmenityList.forEach((e) => {
          this.allAmenityList.forEach((a) => {
            if (e.id == a.feature.id) {
              e.enabled = a.enabled;
            }
          });
        });
      }

      this.reqGetHotelAmenityAll(callback);
    },

    /** 全ての設備・特徴を取得 */
    reqGetAllFeatures() {

      const callback = (a) => {
        const features = a.features;
        this.reqGetAllSelectedFeatures(features);
      }

      this.reqGetFeatureAll(callback);
    },

    /** 宿の設備・特徴を取得 */
    reqGetAllSelectedFeatures(data) {

      const callback = (a) => {
        this.getFeaturesList = data;
        this.allFeaturesList = a.features;
        this.getFeaturesList.forEach((e) => {
          this.allFeaturesList.forEach((a) => {
            if (e.id == a.feature.id) {
              e.enabled = a.enabled;
            }
          });
        });
      }

      this.reqGetHotelFeatureAll(callback);
    },

    /**全てのプロパティ取得 */
    reqGetAllProperties() {
      const callback = (a) => {
        this.allProperties = [];
        this.isLoad = true;
        const allList = a.properties;

        // only below selected properties are been shown
        const selectedList = [
          this.PROPERTYLIST.others,
          this.PROPERTYLIST.aboutAmenity,
          this.PROPERTYLIST.aboutFeatures,
        ];

        this.allProperties = allList.filter((a) =>
          selectedList.some((k) => a.id == k)
        );

        this.reqGetAllSelectedProperties();
      }

      this.reqGetPropertyAll(callback);
    },

    /**宿のプロパティ取得 */
    reqGetAllSelectedProperties() {
      const callback = (a) => {
        const getNewD = a.properties;
        // match data with all data
        this.allProperties.forEach((e) => {
          e.description = "";
          getNewD.forEach((a) => {
            if (a.propertyId == e.id) {
              e.pId = a.propertyId;
              e.description = a.description;
              e.status = a.status;
            }
          });
        });

        this.allProperties.forEach((e) => {
          if (e.id == this.PROPERTYLIST.others) this.othersProperty = e;
          if (e.id == this.PROPERTYLIST.aboutAmenity) this.aboutAmenity = e;
          if (e.id == this.PROPERTYLIST.aboutFeatures) this.aboutFeatures = e;
        });

        this.reload++;
      }

      this.reqGetHotelOptionAll(callback);
    },

    /**選択したアメニティを登録 */
    reqPostSelectedAmenities() {

      const body = [];

      this.getAmenityList.filter((a) =>
        a.enabled == true
          ? body.push(a.id)
          : ""
      );


      const callback = () => {
        this.reqPostSelectedFeatures();
      }

      const errorCallback = (err) => {
        this.errorMessage = err;
        this.errorModal = true;
      };

      this.reqPostHotelAmenity(body, callback, errorCallback);
    },

    /**選択した設備・特徴を登録 */
    reqPostSelectedFeatures() {

      const body = [];

      this.getFeaturesList.filter((a) =>
        a.enabled == true
          ? body.push(a.id)
          : ""
      );

      const callback = () => {
        this.reqPostTextContents();
      }

      const errorCallback = (err) => {
        this.errorMessage = err;
        this.errorModal = true;
      };

      this.reqPostHotelFeature(body, callback, errorCallback);
    },

    /**アメニティ・設備・その他のテキストエリアの内容を登録 */
    reqPostTextContents() {

      const body = [];
      const mapPsot = [];
      //set language
      this.aboutAmenity.language = this.$i18n.locale;
      this.aboutFeatures.language = this.$i18n.locale;
      this.othersProperty.language = this.$i18n.locale;

      mapPsot.push(this.aboutAmenity, this.aboutFeatures, this.othersProperty);
      mapPsot.filter((e) => {
        if ((e.description && e.description.length >= 1) || e.id) {
          body.push({
            propertyId: e.id,
            description: e.description,
            language: this.$i18n.locale,
          });
        }
      });

      const callback = () => {
        this.successModal = true;
        this.reqGetAllAmenities();
        this.reqGetAllFeatures();
        this.reqGetAllSelectedProperties();
      }

      const errorCallback = (err) => {
        this.errorMessage = err;
        this.errorModal = true;
      };

      this.reqPostHotelOption(body, callback, errorCallback);
    },

  },
  beforeMount() {
    this.reqGetAllProperties();
    this.reqGetAllAmenities();
    this.reqGetAllFeatures();
  },
};
</script>
<style lang="scss">
.edit_options {
  .edit_form {
    .row {
      .col,
      .col-md-3 {
        padding: 0.5rem 0;
      }
    }
  }
}
.input-text-dark {
  .form-control {
    color: #3c4b64 !important;
  }
}
</style>