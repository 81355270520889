<template>
  <div class="edit_text_area">
    <h4 class="sub-title">{{$t('facilityEditPage.referralInformation')}}</h4>
    （宿詳細ページの見映えのため、2つ以上のカテゴリー記入を推奨）
    <ul class="seletect_cat">
      <li>
        <b>{{$t('common.category')}}</b>
      </li>
      <li>
        <CSelect
          :options="CategoryList"
          :value.sync="selectedCategory"
          @change="changeContentByCat()"
        />
      </li>
    </ul>

    <div class="editor_top_section">
      <!-- <strong class="cat_title my-2">{{CatTitle}}</strong> -->

      <CRow class="editor_form swithch_sec">
        <CCol>
          <CRow>
            <CCol>
              <h5 class="editor_title">{{$t('common.title')}}</h5>
              <CInput v-model="facilityContent.title" />
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <h5 class="editor_title">{{$t('common.shortDescription')}}</h5>
              <CTextarea
                rows="4"
                v-model="facilityContent.description"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <h5 class="editor_title">{{$t('yadoEdit.textEditor.imageSlider')}}</h5>
              <ImageSelectModal
                :images="facilityContent.images"
                :imgCategory="imgCatId"
                @selected-images="facilityContent.images =$event"
                :key="facilityContent.images"
              />
              <span class="text-danger">※プレビュー画面表示のため、1ファイル以上の画像登録必須</span>
            </CCol>
          </CRow>

        </CCol>
      </CRow>

      <div class="card_group border-white">

        <div
          class="multi_card"
          v-for="(card, index) in facilityContent.child"
          :key="card.id"
        >

          <h5 class="cat_title mr-3">{{$t('yadoEdit.textEditor.cards') + ++index }}</h5>
          <CButton
            class="float-right px-2 pb-0"
            color="danger"
            size="sm"
            @click="card.id ? deleteOldCard(card.id) :deleteNewCard(index)"
          ><i class="icon cil-minus"></i></CButton>
          <!-- delete card  -->

          <CRow class="editor_form mx-4">
            <CCol>
              <CRow>
                <CCol>
                  <h5 class="editor_title">{{$t('common.title')}}</h5>
                  <CInput v-model="card.title" />
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <h5 class="editor_title">{{$t('common.shortDescription')}}</h5>
                  <CTextarea
                    rows="4"
                    v-model="card.description"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <h5 class="editor_title">{{$t('yadoEdit.textEditor.imageSlider')}}</h5>
                  <p
                    class="note-info my-2"
                    v-if="selectedCategory ==1"
                  >カテゴリー「トピックス」のスライダー画像は、1枚目の画像のみ宿詳細ページに表示対象となります。<br>
                    ※2枚目以降の画像は表示対象外となります。</p>
                  <ImageSelectModal
                    :images="card.images"
                    :imgCategory="imgCatId"
                    @selected-images="card.images =$event"
                    :key="card.images"
                  />

                </CCol>
              </CRow>

            </CCol>
          </CRow>

        </div>
        <!-- multi_card end  -->
        <!-- <CRow>
          <CCol>
            <pre><code>{{facilityContent}}</code></pre>
          </CCol>
          <CCol>
            <pre><code>{{checkForm}}</code></pre>

          </CCol>
        </CRow> -->

        <CButton
          color="info"
          class="mb-3"
          @click="addNewCard()"
        >+ {{$t('yadoEdit.textEditor.cards') + " "+$t('common.add')}}</CButton>
      </div>

    </div>
    <!-- editor_top_section end  -->
    <CButton
      @click="reqPutFacilityDetail()"
      color="success"
    >
      <i class="icon cil-save"></i> {{ $t("common.update") }}
    </CButton>
    <!-- <pre><code>{{facilityContent}}</code></pre> -->

    <CModal
      color="warning"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="warningModal"
    >
      {{ $t("commonMessage.deleteText") }}
      <template #header> {{ $t("commonMessage.deleteTitle") }} </template>
      <template #footer>
        <CButton
          @click="cancelCardWarning()"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{ $t("common.cancel") }}
        </CButton>
        <CButton
          @click="reqDeleteFacilityDetailCard()"
          color="success"
        >
          <i class="icon cil-save"></i> {{ $t("common.submit") }}
        </CButton>
      </template>
    </CModal>
    <CModal
      color="warning"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="changeConfirmModal"
    >
      <!-- {{ $t("commonMessage.switchWarningText") }} -->
      <!-- fix me  -->
      <p v-html="$t('commonMessage.switchWarningText')"></p>
      <template #header> {{ $t("common.check") }} </template>
      <template #footer>
        <CButton
          @click="cancelConfirm()"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{ $t("common.cancel") }}
        </CButton>
        <CButton
          @click="changeConfirm()"
          color="success"
        >
          <i class="icon cil-check-circle"></i> OK
        </CButton>
      </template>
    </CModal>
    <CModal
      color="success"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="successModal"
    >
      {{ $t("commonMessage.updateSuccessText") }}
      <template #header> {{ $t("commonMessage.updateSuccessHeader") }} </template>
      <template #footer>
        <CButton
          @click="successModal = false"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{ $t("common.close") }}
        </CButton>
      </template>
    </CModal>

    <CModal
      color="danger"
      closeOnBackdrop
      :centered="true"
      :show.sync="errorModal"
    >
      <p>{{ errorMessage }}</p>
      <template #header>
        <h4 class="modal-title"> {{$t('common.error')}}</h4>
      </template>
      <template #footer>
        <CButton
          @click="errorModal = false"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{$t('common.close')}}
        </CButton>
      </template>
    </CModal>

  </div>
</template>

<script>
import ImageSelectModal from "@/components/ImageSelectModal.vue";
import PROPERTYLIST from "@/mixins/property";

export default {
  mixins: [PROPERTYLIST],
  name: "EditTextArea",
  components: {
    ImageSelectModal,
  },
  data() {
    return {
      successModal: false,
      errorModal: false,
      editCardModal: false,
      galleryModal: false,
      changeConfirmModal: false,
      warningModal: false,
      oldCardId: "",
      CategoryList: [],
      selectedCategory: 0,
      errorMessage: "",
      facilityContent: {
        title: "",
        description: "",
        images: [],
        child: [
          {
            title: "",
            description: "",
            images: [],
          },
        ],
      },
      checkForm: {},
    };
  },

  computed: {
    // CatTitle() {
    //   var subTitle;
    //   this.CategoryList.forEach((a) => {
    //     if (a.value == this.$store.state.editActiveCategoryNo)
    //       subTitle = a.label;
    //   });
    //   return subTitle;
    // },
    imgCatId() {
      var ImageCategory;

      switch (this.selectedCategory) {
        case 29:
          // おすすめ
          ImageCategory = 0;
          break;

        case 2:
          // お部屋
          ImageCategory = 1;
          break;

        case 46:
          // お風呂
          ImageCategory = 2;
          break;

        case 10:
          // 朝食
          ImageCategory = 3;
          break;

        case 4:
          // サービス
          ImageCategory = 5;
          break;

        case 14:
          // その他
          ImageCategory = 8;
          break;

        default:
          // すべて
          ImageCategory = 0;
      }

      return ImageCategory;
    },

    /**宿ID取得 */
    uid() {
      return this.$store.state.uniqueId;
    }
  },

  methods: {

    /**全てのプロパティ取得 */
    reqGetAllProperties() {

      const callback = (a) => {
        this.allProperties = [];
        this.isLoad = true;
        const allList = a.properties;
        // only below selected properties are been shown
        const selectedList = [
          this.PROPERTYLIST.recomendation,
          this.PROPERTYLIST.room,
          this.PROPERTYLIST.bathAndOnsen,
          this.PROPERTYLIST.food,
          this.PROPERTYLIST.equipmentServices,
          this.PROPERTYLIST.others,
        ];
        // match with selected property
        allList.forEach((a) => {
          selectedList.forEach((l, k) => {
            if (a.id == l) {
              this.CategoryList.push({
                viewOrder: k,
                label: a.name,
                value: a.id,
              });
            }
          })
        });
        // sort by order
        this.CategoryList.sort((a, b) => a.viewOrder - b.viewOrder);
      }
      this.reqGetPropertyAll(callback);
    },

    /**
     * get content by  category
     */
    changeContentByCat() {
      const changeCheck = JSON.stringify(this.facilityContent) === this.checkForm;

      if (changeCheck) {
        this.$store.commit("set", [
          "editActiveCategoryNo",
          this.selectedCategory,
        ]);
        this.reqGetFacilityDetail();
      } else {
        this.changeConfirmModal = true;
      }
    },
    /** button: change confirm  */
    changeConfirm() {
      this.$store.commit("set", [
        "editActiveCategoryNo",
        this.selectedCategory,
      ]);
      this.reqGetFacilityDetail();
      this.changeConfirmModal = false;
    },
    /** button: cancel confirm */
    cancelConfirm() {
      this.changeConfirmModal = false;
      this.selectedCategory = this.$store.state.editActiveCategoryNo;
    },

    /**宿の詳細情報 */
    reqGetFacilityDetail() {

      this.selectedCategory = this.$store.state.editActiveCategoryNo;
      this.facilityContent = {
        title: "",
        description: "",
        images: [],
        child: [
          {
            title: "",
            description: "",
            images: [],
          },
        ],
      };

      this.checkForm = JSON.stringify(this.facilityContent);

      const callback = (a) => {
        this.facilityContent = a;
        this.facilityContent.child.sort((a, b) => a.id - b.id);
        this.checkForm = JSON.stringify(this.facilityContent);
      }
      this.reqGetHotelDetailByPropertyId(this.selectedCategory, callback);
    },


    /**宿の詳細情報更新 */
    reqPutFacilityDetail() {

      const propertyId = this.selectedCategory;
      delete this.facilityContent.hotelId;
      delete this.facilityContent.propertyId;
      this.facilityContent.child.map((e) => {
        delete e.hotelId;
        delete e.propertyId;
      });
      this.facilityContent.language = this.$i18n.locale;

      const body = this.facilityContent;

      const callback = () => {
        this.reqGetFacilityDetail();
        this.successModal = true;
      }

      const errorCallback = (err) => {
        this.errorMessage = err;
        this.errorModal = true;
      }
      this.reqPutHotelDetailByPropertyId(propertyId, body, callback, errorCallback);
    },

    /**
     * add new card
     * */
    addNewCard() {
      this.facilityContent.child.push({
        title: "",
        description: "",
        images: [],
      });
    },

    /** delete new card */
    deleteNewCard(index) {
      index = index - 1;
      this.facilityContent.child.splice(index, 1);
    },

    /** delete old card */
    deleteOldCard(id) {
      this.warningModal = true;
      this.oldCardId = id;
    },

    /** warningModal close */
    cancelCardWarning() {
      this.warningModal = false;
      this.oldCardId = "";
    },


    /**宿詳細情報のカードを削除 */
    reqDeleteFacilityDetailCard() {

      const propertyId = this.selectedCategory;
      const itemId = this.oldCardId;

      const callback = () => {
        this.warningModal = false;
        this.successModal = true;
        this.reqGetFacilityDetail();
      }

      const errorCallback = (err) => {
        this.errorMessage = err;
        this.errorModal = true;
      }
      this.reqDeleteHotelDetailByPropertyItemId(propertyId, itemId, callback, errorCallback);
    },

    /** close modal */
    closeModal() {
      this.galleryModal = false;
    },
  },
  beforeMount() {
    this.reqGetAllProperties();
    this.reqGetFacilityDetail();
    // this.getHotelDetails();
  },
};
</script>

<style lang="scss">
.cat_title {
  border-bottom: 2px solid #4171b4;
  padding-bottom: 4px;
  display: inline-block;
}
.swithch_sec {
  margin-bottom: 20px;
}
.row {
  margin-left: 0;
  margin-right: 0;
}
.editor_form {
  .col {
    padding: 10px 0;
  }
}
.seletect_cat {
  padding: 10px 0;
  border-bottom: 2px solid #aab2c0;
  li {
    display: inline-block;
    margin-right: 15px;
  }
}
.card_group {
  padding: 15px 15px 0;
  border: 1px solid #cccccc;
  border-radius: 5px;
  margin-bottom: 2%;
}
</style>

