<template>
  <div class="edit_basic_options">
    <h4 class="sub-title">{{$t('facilityEditPage.mealInformation')}}</h4>

    <div
      class="edit_form"
      v-if="isLoad"
    >

      <!-- <code>{{allPropertiesView}}</code> -->
      <CRow
        class="border-bottom"
        v-for="property in allPropertiesView"
        :key="property.id"
      >
        <CCol md="3"><strong class="form_lable">{{ property.name }}</strong>
        </CCol>
        <CCol>
          <!-- <ServiceOptionModal
            :propertyItem="property"
            @property-status="property.status = $event"
            @property-description="property.description = $event"
            :key="property.status"
          /> -->
          <template v-if="[30].includes(property.id)">
            <!-- 朝食 -->
            <CInputCheckbox
              class="mx-1"
              v-for="(tag, n) in breakfastList"
              :key="n + tag"
              :label="tag.label"
              :value="tag.value"
              custom
              inline
              :checked.sync="tag.enabled"
            />
            <CTextarea
              rows="3"
              v-model="breakfast.text"
            />
          </template>
          <template v-else-if="[31].includes(property.id)">
            <!-- 夕食 -->
            <CInputCheckbox
              class="mx-1"
              v-for="(tag, n) in dinnerList"
              :key="n + tag"
              :label="tag.label"
              :value="tag.value"
              custom
              inline
              :checked.sync="tag.enabled"
            />
            <CTextarea
              rows="3"
              v-model="dinner.text"
            />
          </template>
          <template v-else>
            <CTextarea
              rows="3"
              v-model="property.description"
            />
          </template>
          <template v-if="[30,31].includes(property.id)">
            <!-- 朝食/夕食 -->
            例）プランによって食事場所が異なります。<br />
            例）プランによって食事時間が異なります。<br />
            例）ビュッフェ形式となります。
          </template>
          <template v-if="[32].includes(property.id)">
            <!-- 昼食 -->
            例）シーズンによっては、日帰りのランチを提供しております。お気軽に問い合わせください。
          </template>
          <template v-if="[11].includes(property.id)">
            <!-- ルームサービス -->
            {{ property.name }}を行っていない場合は非表示(未記入)を推奨します。<br />
            例）19:00～21:00の間で承っております。<br />
            例）おつまみやアルコール類をご用意しております。
          </template>
        </CCol>
      </CRow>
      <!-- onsen options  -->
      <div class="tab_footer">
        <CButton
          class="mt-3"
          color="success"
          @click="reqPostProperties()"
        ><i class="cil-save"></i> {{
        $t("common.update")
      }}</CButton>
      </div>
      <!-- .tab_footer end  -->
    </div>
    <!-- edit_form end -->

    <CModal
      color="success"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="successModal"
    >
      {{ $t("commonMessage.updateSuccessText") }}
      <template #header> {{ $t("commonMessage.updateSuccessHeader") }} </template>
      <template #footer>
        <CButton
          @click="successModal = false"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{$t('common.close')}}
        </CButton>
      </template>
    </CModal>
    <CModal
      color="danger"
      closeOnBackdrop
      :centered="true"
      :show.sync="errorModal"
    >
      <p>{{ errorMessage }}</p>
      <template #header>
        <h4 class="modal-title">{{$t('common.error')}}</h4>
      </template>
      <template #footer>
        <CButton
          @click="errorModal = false"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{$t('common.close')}}
        </CButton>
      </template>
    </CModal>
  </div>
  <!-- edit_options -->
</template>

<script>
// import i18n from "../i18n";
// import ServiceOptionModal from "@/components/ServiceOptionModal";
import PROPERTYLIST from "@/mixins/property";

export default {
  mixins: [PROPERTYLIST],
  name: "EditMeal",
  components: {
    // ServiceOptionModal,
  },
  data() {
    return {
      isLoad: false,
      successModal: false,
      errorModal: false,
      errorMessage: "",
      allProperties: [],
      allPropertiesView: [
        {
          id: "",
          name: "",
          description: "",
          status: "",
        },
      ],

      breakfast: {
        text: "",
        list: [],
      },
      breakfastList: [
        { label: '食事処（レストラン含）', value: 0, enabled: false, },
        { label: '個室食', value: 1, enabled: false, },
        { label: '部屋食', value: 2, enabled: false, },
      ],

      dinner: {
        text: "",
        list: [],
      },
      dinnerList: [
        { label: '食事処（レストラン含）', value: 0, enabled: false, },
        { label: '個室食', value: 1, enabled: false, },
        { label: '部屋食', value: 2, enabled: false, },
      ],
    };
  },

  computed: {
    /**宿ID取得 */
    uid() {
      return this.$store.state.uniqueId;
    },

    placeList() {
      return [
        { label: '食事処（レストラン含）', value: 0, enabled: false, },
        { label: '個室食', value: 1, enabled: false, },
        { label: '部屋食', value: 2, enabled: false, },
      ];
    },
  },

  methods: {
    /**全てのプロパティ取得 */
    reqGetAllProperties() {
      const callback = (a) => {
        this.allProperties = [];
        this.isLoad = true;
        const allList = a.properties;

        // only below selected properties are been shown
        const selectedList = [
          this.PROPERTYLIST.breakfarst,
          this.PROPERTYLIST.dinner,
          this.PROPERTYLIST.lunch,
          this.PROPERTYLIST.roomServices,
        ];

        allList.forEach((a) => {
          selectedList.forEach((l, k) => {
            if (a.id == l) {
              a.viewOrder = k;
              this.allProperties.push(a);
            }
          });
        });

        this.allProperties.sort((a, b) => a.viewOrder - b.viewOrder);
        this.reqGetAllSelectedProperties();
      };

      this.reqGetPropertyAll(callback);
    },

    /**宿のプロパティ取得 */
    reqGetAllSelectedProperties() {
      const callback = (data) => {
        const getNewD = data.properties;

        // match data with all data
        this.allProperties.forEach((e) => {
          e.description = "";
          getNewD.forEach((a) => {
            if (a.propertyId == e.id) {
              e.pId = a.propertyId;
              e.description = a.description;
              e.status = a.status;
            }
          });
        });
        this.allPropertiesView = this.allProperties;

        // 朝食
        const breakfast = this.allPropertiesView.filter(e => e.pId === 30);
        this.breakfast = !breakfast.length
          ? { text: "", list: [] }
          : breakfast.map(f => {
            const description = JSON.parse(f.description);
            // フォーマット誤りはデータ初期化
            return {
              text: description?.text || "",
              list: description?.list || [],
            }
          })[0];
        this.debugLog("this.breakfast");
        this.debugLog(this.breakfast);

        this.breakfastList
          .filter(b => this.breakfast?.list?.includes(b.value))
          .forEach(b => b.enabled = true);
        this.debugLog("this.breakfastList");
        this.debugLog(this.breakfastList);

        // 夕食
        const dinner = this.allPropertiesView.filter(e => e.pId === 31);
        this.dinner = !dinner.length
          ? { text: "", list: [] }
          : dinner.map(f => {
            const description = JSON.parse(f.description);
            // フォーマット誤りはデータ初期化
            return {
              text: description?.text || "",
              list: description?.list || [],
            }
          })[0];
        this.debugLog("this.dinner");
        this.debugLog(this.dinner);

        this.dinnerList
          .filter(b => this.dinner?.list?.includes(b.value))
          .forEach(b => b.enabled = true);
        this.debugLog("this.dinnerList");
        this.debugLog(this.dinnerList);
      };

      this.reqGetHotelOptionAll(callback);
    },

    /**宿のプロパティ登録 */
    reqPostProperties() {

      // 朝食
      this.breakfast.list = this.breakfastList
        .filter(a => a.enabled)
        .map(a => a.value);

      // 夕食
      this.dinner.list = this.dinnerList
        .filter(a => a.enabled)
        .map(a => a.value);

      this.allPropertiesView.forEach(a => {
        switch (a.id) {
          case 30: // 朝食
            a.description = (this.breakfast.list?.length > 0 || this.breakfast?.text) ? JSON.stringify(this.breakfast) : null;
            break;
          case 31: // 夕食
            a.description = (this.dinner.list?.length > 0 || this.dinner?.text) ? JSON.stringify(this.dinner) : null;
            break;
          default:
            break;
        }
      })

      const body = [];
      this.allPropertiesView.filter((e) => {
        if ((e.description && e.description.length >= 1) || e.pId) {
          body.push({
            propertyId: e.id,
            description: e.description,
            status: e.status ? e.status : 0,
            language: this.$i18n.locale,
          });
        }
      });

      const callback = () => {
        this.reqGetAllProperties();
        this.successModal = true;
      };

      const errorCallback = (err) => {
        this.errorMessage = err;
        this.errorModal = true;
      };

      this.reqPostHotelOption(body, callback, errorCallback);
    },
  },

  beforeMount() {
    this.reqGetAllProperties();
  },
};
</script>
<style lang="scss">
.form-group {
  margin: 0;
}
.edit_basic_options {
  .edit_form {
    .row {
      .col,
      .col-md-3 {
        padding: 0.5rem 0.4rem;
        display: inline-block;
      }
    }
    .list-group-item {
      .col-md-3,
      .col {
        padding: 0 0.4rem;
        display: inline-block;
      }
    }
  }
}
</style>