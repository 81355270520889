<template>
  <div class="edit_basi_info">
    <h4 class="sub-title">{{$t('facilityEditPage.primaryInformation')}}</h4>

    <CForm class="submit_form">

      <!-- 基本情報 -->
      <h4 class="form-title">
        {{ $t("facilityEditPage.basicInformation") }}
      </h4>
      <CRow>
        <CCol class="label"><strong>ID</strong></CCol>
        <CCol>
          <CInput
            v-model="editFacility.uniqueId"
            disabled
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol class="label"><strong>{{ $t("facilityEditPage.status") }}</strong></CCol>
        <CCol>
          <CInput
            v-model="hotelStatusLabel"
            disabled
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol class="label"><strong class="required-mark">{{ $t("facilityEditPage.branchOffice") }}</strong> <span
            v-if="isRole"
            class="req-star"
          >*1,*2</span></CCol>
        <CCol sm="3">
          <CSelect
            :options="branchOffice"
            :value.sync="selectedBranch"
          />
          <p
            class="text-danger input-info"
            v-if="$v.editFacility.branchId.$dirty &&!$v.editFacility.branchId.required"
          >
            {{ $t("validations.required", { vName: $t("facilityEditPage.branchOffice") })}}
          </p>
        </CCol>
      </CRow>
      <CRow>
        <CCol class="label"><strong class="required-mark">{{ $t("facilityEditPage.name") }}</strong> <span
            v-if="isRole"
            class="req-star"
          >*1</span></CCol>
        <CCol>
          <CInput
            v-model="editFacility.name"
            :disabled="!isRole"
          />
          <p
            class="text-danger input-info"
            v-if="$v.editFacility.name.$dirty &&!$v.editFacility.name.required"
          >
            {{ $t("validations.required", { vName: $t("facilityEditPage.name") })}}
          </p>

        </CCol>
      </CRow>
      <CRow>
        <CCol class="label"><strong class="required-mark">{{ $t("facilityEditPage.pronunciation") }}</strong><span
            v-if="isRole"
            class="req-star"
          >*1,*2</span></CCol>
        <CCol>
          <CInput
            v-model="editFacility.pronunciation"
            :disabled="!isRole"
          />
          <p
            class="text-danger input-info"
            v-if="$v.editFacility.pronunciation.$dirty &&!$v.editFacility.pronunciation.required"
          >
            {{ $t("validations.required", { vName: $t("facilityEditPage.pronunciation") })}}
          </p>
          <p
            class="text-danger input-info"
            v-if="$v.editFacility.pronunciation.$dirty && !$v.editFacility.pronunciation.isKana"
          >
            {{ $t("validations.kana")}}
          </p>
        </CCol>
      </CRow>
      <CRow>
        <CCol class="label"><strong>{{ $t("facilityEditPage.description") }}</strong></CCol>
        <CCol>
          <CTextarea
            rows="2"
            v-model="editFacility.desciption"
          />
          ※100文字まで
          <p
            class="text-danger input-info"
            v-if="$v.editFacility.desciption.$dirty &&!$v.editFacility.desciption.maxLength"
          >
            {{ $t("validations.maxLength", { vName: $t("facilityEditPage.description"), vMax: 100 })}}
          </p>
        </CCol>
      </CRow>

      <!-- 連絡先 -->
      <h4 class="form-title">
        {{ $t("facilityEditPage.contactInformation") }}
      </h4>
      <CRow>
        <CCol class="label"><strong class="required-mark">{{ $t("facilityEditPage.telephone") }}</strong><span
            v-if="isRole"
            class="req-star"
          >*1</span></CCol>
        <CCol>
          <CInput
            v-model="editFacility.telephone"
            :disabled="!isRole"
            @blur="onFormBlur('telephone')"
          />
          <p
            class="text-danger input-info"
            v-if="$v.editFacility.telephone.$dirty && !$v.editFacility.telephone.required"
          >
            {{ $t("validations.required", { vName: $t("facilityEditPage.telephone") })}}
          </p>
          <p
            class="text-danger input-info"
            v-if=" $v.editFacility.telephone.$dirty && !$v.editFacility.telephone.isIntDsh "
          >
            {{ $t("validations.numeric", { vName: $t("facilityEditPage.telephone") })}}
          </p>
          <!-- TODO:バリデーション有効化したら使用する -->
          <!-- <p
            class="text-danger input-info"
            v-if=" $v.editFacility.telephone.$dirty && !$v.editFacility.telephone.maxLength "
          >
            {{ $t("validations.maxLength", { vName: $t("facilityEditPage.telephone"), vMax: $v.editFacility.telephone.$params.maxLength.max })}}
          </p> -->
        </CCol>
      </CRow>
      <CRow>
        <CCol class="label"><strong>{{ $t("facilityEditPage.fax") }}</strong><span
            v-if="isRole"
            class="req-star"
          >*1,*2</span></CCol>
        <CCol>
          <CInput
            v-model="editFacility.fax"
            :disabled="!isRole"
            @blur="onFormBlur('fax')"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol class="label"><strong>{{ $t("facilityEditPage.url") }}</strong><span
            v-if="isRole"
            class="req-star"
          >*1,*2</span></CCol>
        <CCol>
          <CInput
            v-model="editFacility.url"
            :disabled="!isRole"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol class="label"><strong>{{ $t("facilityEditPage.email") }}</strong><span
            v-if="isRole"
            class="req-star"
          >*1,*2</span></CCol>
        <CCol>
          <CInput
            v-model="editFacility.email"
            :disabled="!isRole"
          />
        </CCol>
      </CRow>
      <CRow class="mb-3">
        <CCol class="label"><strong>{{ $t("facilityEditPage.contactEmail") }}</strong><span
            v-if="isRole"
            class="req-star"
          >*1,*2</span></CCol>
        <CCol>
          <CInput
            v-model="editFacility.contactEmail"
            :disabled="!isRole"
          />
        </CCol>
      </CRow>

      <!-- アクセス情報 -->
      <h4 class="form-title">
        {{ $t("facilityEditPage.accessInformation") }}
      </h4>
      <CRow>
        <CCol class="label"><strong class="required-mark">{{ $t("facilityEditPage.zipcode") }}</strong><span
            v-if="isRole"
            class="req-star"
          >*1</span></CCol>
        <CCol lg="3">
          <CInput
            v-model="editFacility.zipcode"
            :disabled="!isRole"
            @blur="onFormBlur('zipcode')"
          />
          <p
            class="text-danger input-info"
            v-if=" $v.editFacility.zipcode.$dirty && !$v.editFacility.zipcode.required "
          >
            {{ $t("validations.required", { vName: $t("facilityEditPage.zipcode") })}}
          </p>
          <p
            class="text-danger input-info"
            v-if=" $v.editFacility.zipcode.$dirty && !$v.editFacility.zipcode.isIntDsh  "
          >
            {{ $t("validations.numeric", { vName: $t("facilityEditPage.zipcode") })}}
          </p>
          <!-- TODO:バリデーション有効化したら使用する -->
          <!-- <p
            class="text-danger input-info"
            v-if=" $v.editFacility.zipcode.$dirty && !$v.editFacility.zipcode.maxLength "
          >
            {{ $t("validations.maxLength", { vName: $t("facilityEditPage.zipcode"), vMax: $v.editFacility.zipcode.$params.maxLength.max-1 })}}
          </p> -->
          <!-- TODO:バリデーション有効化したら使用する -->
          <!-- <p
            class="text-danger input-info"
            v-if=" $v.editFacility.zipcode.$dirty && !$v.editFacility.zipcode.minLength  "
          >
            {{ $t("validations.minLength", { vName: $t("facilityEditPage.zipcode"), vMin: $v.editFacility.zipcode.$params.minLength.min })}}
          </p> -->
        </CCol>
        <CCol lg="4">
          <CButton
            color="success"
            @click="getAddress()"
          >
            <i class="icon cil-location-pin"></i> {{$t("common.addressSearchButton")}}
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol class="label"><strong class="required-mark">{{ $t("facilityEditPage.address1") }}</strong><span
            v-if="isRole"
            class="req-star"
          >*1</span></CCol>
        <CCol>
          <CInput
            v-model="editFacility.address1"
            :placeholder="$t('facilityEditPage.address1Holder')"
            :disabled="!isRole"
          />
          <p
            class="text-danger input-info"
            v-if=" $v.editFacility.address1.$dirty && !$v.editFacility.address1.required "
          >
            {{ $t("validations.required", { vName: $t("facilityEditPage.address1") })}}
          </p>
        </CCol>
      </CRow>
      <CRow class="mb-3">
        <CCol class="label"><strong>{{ $t("facilityEditPage.address2") }}</strong><span
            v-if="isRole"
            class="req-star"
          >*1</span></CCol>
        <CCol>
          <CInput
            v-model="editFacility.address2"
            :placeholder="$t('facilityEditPage.address2Holder')"
            :disabled="!isRole"
          />
        </CCol>
      </CRow>

      <!-- #region エリア -->
      <h4 class="form-title">
        {{ $t("facilityEditPage.locationInformation") }}
      </h4>
      <CRow
        v-for="(location) in editFacility.locations"
        :key="location.id"
      >
        <CCol class="label"><strong>{{ LOCATION_LABEL_LIST[location.level-1] }}</strong></CCol>
        <CCol>
          <CInput
            v-model="location.name"
            disabled
          />
        </CCol>
      </CRow>
      <CRow class="mb-3">
      </CRow>
      <!-- #endregion エリア -->

      <!-- #region アイキャッチ画像 -->
      <h4 class="form-title">
        {{ $t("facilityEditPage.topImage") }}
      </h4>
      <CRow>
        <CCol class="label"><strong>{{ $t("imageGallery.image") }}</strong><span
            v-if="isRole"
            class="req-star"
          >*1</span></CCol>
        <CCol>
          <ImageSelectModal
            :images="IntroImg"
            @selected-images="IntroImg =$event"
            :isDraggable="!isRole"
            :key="IntroImg"
          />
          ※5つまで登録可能
        </CCol>
      </CRow>
      <!-- #endregion アイキャッチ画像 -->

    </CForm>

    <CRow>
      <span
        v-if="isRole"
        class="mt-1 text-dark"
      >*1 サイト管理者・スタッフ権限のみ編集可能</span>
    </CRow>
    <CRow>
      <span
        v-if="isRole"
        class="mt-1 text-dark"
      >*2 宿詳細ページには表示されません</span>
    </CRow>

    <!-- 確認ボタン -->
    <CButton
      class="px-5 mt-3"
      color="info"
      @click="$v.editFacility.$invalid ? requiredFd() : confirmM()"
      v-bind:class="$v.editFacility.$invalid ? 'inactive' : 'active'"
    >
      <i class="cil-task"></i> {{
      $t("common.check")
    }}
    </CButton>

    <!-- 確認ダイアログ -->
    <CModal
      color="info"
      size="lg"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="checkModal"
    >
      <CForm class="submit_form">
        <h4 class="form-title">{{ $t("facilityEditPage.basicInformation") }}</h4>
        <CRow>
          <CCol class="label"><strong>ID</strong></CCol>
          <CCol><span>{{ editFacility.uniqueId }}</span></CCol>
        </CRow>
        <CRow>
          <CCol class="label"><strong>{{ $t("facilityEditPage.status") }}</strong></CCol>
          <CCol><span>{{ editFacility.status }}</span></CCol>
        </CRow>
        <CRow>
          <CCol class="label"><strong>{{ $t("facilityEditPage.branchOffice") }}</strong></CCol>
          <CCol><span>{{ selectedBranchLabel }}</span></CCol>
        </CRow>
        <CRow>
          <CCol class="label"><strong>{{ $t("facilityEditPage.name") }}</strong></CCol>
          <CCol><span>{{ editFacility.name }}</span></CCol>
        </CRow>
        <CRow>
          <CCol class="label"><strong>{{ $t("facilityEditPage.pronunciation") }}</strong></CCol>
          <CCol><span>{{ editFacility.pronunciation }}</span></CCol>
        </CRow>
        <CRow>
          <CCol class="label"><strong>{{ $t("facilityEditPage.description") }}</strong></CCol>
          <CCol><span>{{ editFacility.desciption }}</span></CCol>
        </CRow>

        <h4 class="form-title">
          {{ $t("facilityEditPage.contactInformation") }}
        </h4>
        <CRow>
          <CCol class="label"><strong>{{ $t("facilityEditPage.telephone") }}</strong></CCol>
          <CCol><span>{{ editFacility.telephone }}</span></CCol>
        </CRow>
        <CRow>
          <CCol class="label"><strong>{{ $t("facilityEditPage.fax") }}</strong></CCol>
          <CCol><span>{{ editFacility.fax }}</span></CCol>
        </CRow>
        <CRow>
          <CCol class="label"><strong>{{ $t("facilityEditPage.url") }}</strong></CCol>
          <CCol><span>{{ editFacility.url }}</span></CCol>
        </CRow>
        <CRow>
          <CCol class="label"><strong>{{ $t("facilityEditPage.email") }}</strong></CCol>
          <CCol><span>{{ editFacility.email }}</span></CCol>
        </CRow>
        <CRow>
          <CCol class="label"><strong>{{ $t("facilityEditPage.contactEmail") }}</strong></CCol>
          <CCol><span>{{ editFacility.contactEmail }}</span></CCol>
        </CRow>
        <h4 class="form-title">
          {{ $t("facilityEditPage.accessInformation") }}
        </h4>
        <CRow>
          <CCol class="label"><strong>{{ $t("facilityEditPage.zipcode") }}</strong></CCol>
          <CCol><span>{{ editFacility.zipcode }}</span></CCol>
        </CRow>
        <CRow>
          <CCol class="label"><strong>{{ $t("facilityEditPage.address1") }}</strong></CCol>
          <CCol><span>{{ editFacility.address1 }}</span></CCol>
        </CRow>
        <CRow>
          <CCol class="label"><strong>{{ $t("facilityEditPage.address2") }}</strong></CCol>
          <CCol><span>{{ editFacility.address2 }}</span></CCol>
        </CRow>

        <div
          v-for="(location, index) in editFacility.locations"
          :key="location.id"
        >
          <h4 class="form-title">
            {{ $t("facilityEditPage.locationInformation") }} {{ ++index }}
          </h4>
          <CRow>
            <CCol class="label"><strong>{{ $t("facilityEditPage.locationInformation") }}</strong></CCol>
            <CCol><span>{{ location.name }}</span></CCol>
          </CRow>
        </div>
        <h4 class="form-title mt-3">
          {{ $t("facilityEditPage.topImage") }}
        </h4>
        <CRow>
          <CCol class="label"><strong>{{ $t("imageGallery.image") }}</strong></CCol>
          <CCol>
            <ul class="img_list">
              <li
                v-for="(image, index) in IntroImg"
                :key="image + index"
              ><img :src="imgSm+image.fileName"></li>
            </ul>
          </CCol>
        </CRow>
      </CForm>

      <template #header> {{ $t("common.check") }} </template>
      <template #footer>
        <CButton
          @click="checkModal = false"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{ $t("common.cancel") }}
        </CButton>
        <CButton
          color="success"
          @click="reqPutFacility()"
        ><i class="cil-save"></i> {{
          $t("common.update")
        }}</CButton>
      </template>
    </CModal>

    <!-- 更新完了ダイアログ -->
    <CModal
      color="success"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="successModal"
    >
      {{ $t("commonMessage.updateSuccessText") }}
      <template #header> {{ $t("commonMessage.updateSuccessHeader") }} </template>
      <template #footer>
        <CButton
          @click="successModal = false"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{ $t("common.close") }}
        </CButton>
      </template>
    </CModal>

    <!-- エラーダイアログ -->
    <CModal
      color="danger"
      closeOnBackdrop
      :centered="true"
      :show.sync="errorModal"
    >
      <p>{{ errorMessage }}</p>
      <template #header>
        <h4 class="modal-title">Error</h4>
      </template>
      <template #footer>
        <CButton
          @click="errorModal = false"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{ $t("common.close") }}
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import i18n from "@/i18n";
import LOCATION_LABEL_LIST from "@/mixins/property";
import HOTEL_STATUS_LABEL from "@/mixins/property";

import ImageSelectModal from "@/components/ImageSelectModal.vue";
import {
  required,
  // minLength,
  maxLength,
  helpers,
} from "vuelidate/lib/validators";

// const zipcode_url = 
// "https://jxamy7mdqg.execute-api.ap-northeast-1.amazonaws.com/api/";

const ROLE = {
  ADMIN: "yado_operator_admin",
  STAFF: "yado_operator_staff",
};

export default {
  name: "EditFacility",

  mixins: [LOCATION_LABEL_LIST, HOTEL_STATUS_LABEL],

  components: {
    ImageSelectModal,
  },

  data() {
    return {
      successModal: false,
      errorModal: false,
      checkModal: false,

      /**支店一覧 */
      branchOffice: [],
      selectedBranch: "",

      editFacility: {
        id: "",
        uniqueId: "",
        branchId: "",
        telephone: "",
        fax: "",
        url: "",
        email: "",
        contactEmail: "",
        zipcode: "",
        status: 100,
        latitude: null,
        longitude: null,
        name: "",
        pronunciation: "",
        desciption: null,
        address1: "",
        address2: "",
        locations: [
          {
            id: "",
            name: "",
            pronunciation: "",
            prefecture: "",
            town: "",
            pref_pron: "",
            town_pron: "",
            description: "",
            level: null,
          },
        ],
      },
      errorMessage: "",
      IntroImg: [],
      IntroImgList: [],
      refreash: 0,
      // ImgUrl: "https://picture.liberty-resort.net/files/",
      imgSm: config.IMAGE_SM_URL, // small size
    };
  },

  validations() {
    const isKana = helpers.regex("isKana", /^([ァ-ヶ][ァ-ヶー・\s]*)$/);
    const isIntDsh = helpers.regex("isIntDsh", /^[0-9-]*$/);
    return {
      editFacility: {
        desciption: { maxLength: maxLength(100) },
        telephone: { required, isIntDsh, maxLength: maxLength(22) },
        zipcode: {
          required,
          //TODO:登録作業を行う為、桁数のバリデーションを外す
          // minLength: minLength(7),
          isIntDsh,
          //TODO:登録作業を行う為、桁数のバリデーションを外す
          // maxLength: maxLength(7),
        },
        branchId: {
          required
        },
        name: { required, maxLength: maxLength(100) },
        pronunciation: { required, isKana, maxLength: maxLength(100) },
        address1: { required, maxLength: maxLength(100) },
      },
    };
  },

  computed: {
    /** ロールの判定 */
    isRole() {
      // アカウントのロールリスト
      const roles = this.$keycloak.realmAccess.roles;
      // adminかstaff権限を持っているときtrueそれ以外はfalse
      return roles.includes(ROLE.ADMIN) || roles.includes(ROLE.STAFF);
    },

    hotelStatusLabel() {
      const label = this.HOTEL_STATUS_LABEL
        .filter(a => a.value === this.editFacility.status)
        .map(a => a.label);
      return label.length ? label[0] : 'Unknown';
    },

    /**宿ID取得 */
    uid() {
      return this.$store.state.uniqueId;
    },

    /**選択中の拠点の初期表示 */
    initSelectedBranch() {
      return this.editFacility.branchId
        ? this.editFacility.branchId
        : "";
    },
    /**選択中の拠点の確認モーダル表示用ラベル */
    //初期設定時は宿のbranchがnullの為、選択したIDから拠点名を検索
    selectedBranchLabel() {
      const branchObj = this.branchOffice.find((obj) => obj.value === this.selectedBranch);
      return branchObj.label
    },
  },

  watch: {
    selectedBranch() {
      this.$nextTick(() => {
        this.editFacility.branchId = this.selectedBranch;
        console.log(this.editFacility.branchId)
      })
    },
  },

  methods: {

    //#region Event
    onFormBlur(key) {
      this.removeHyphen(key);
    },
    //#endregion Event

    // region Request

    /** 宿情報取得 */
    reqGetFacility() {
      this.facilities = [];
      this.facilityNotFound = false;

      const callback = (a) => {
        this.editFacility = a;
        this.$emit('facility', a);
        // データ取得して初期値設定
        this.selectedBranch = this.initSelectedBranch;
        this.removeHyphen('telephone');
        this.removeHyphen('fax');
        this.removeHyphen('zipcode');

        this.reqGetFacilityImg();
      };

      this.reqGetHotel(callback);
    },

    /**支店情報取得 */
    reqGetAllBranch() {
      this.facilities = [];
      this.facilityNotFound = false;

      const requestType = "s";

      const callback = (a) => {
        const branchList = a.list;

        branchList.forEach(branch => {
          const branchObj = {};

          branchObj.value = branch.id;
          branchObj.label = branch.name;
          this.branchOffice.push(branchObj);

        });

        //先頭に空の要素追加
        this.branchOffice.unshift({ value: "", label: `${i18n.t("common.selectGuid")}` })

        console.log(this.branchOffice);
      };

      this.reqGetBranchAllByType(requestType, callback);
    },

    /** 宿の写真取得 */
    reqGetFacilityImg() {
      const propertyid = "24";

      const callback = (a) => {
        this.IntroImg = a.images;
        this.IntroImgList = this.IntroImg.slice(1);
      };

      this.reqGetHotelDetailByPropertyId(propertyid, callback);
    },


    /**宿情報更新 */
    reqPutFacility() {
      const body = this.editFacility;

      const callback = () => {
        this.checkModal = false;
        // this.successModal = true;
        this.reqPostfacilityImg();
        this.reqGetFacility();
        this.refreash++;
        this.$emit("updateProfile", this.refreash);
      };

      const errorCallback = (err) => {
        this.errorMessage = err;
        this.errorModal = true;
      };

      this.reqPutHotel(body, callback, errorCallback);
    },

    /**住所取得 */
    getAddress: function () {
      // Get address by zipcode
      const urlRequest =
        config.ZIPCODE_URL + "?postcode=" + this.editFacility.zipcode;

      axios
        .get(urlRequest)
        .then((resp) => {
          console.log("resp.data: ", resp.data);
          this.editFacility.address1 = `${resp.data.PrefecturesName}${resp.data.CityName}${resp.data.TownName}`;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**宿写真登録 */
    reqPostfacilityImg() {

      const body = {
        propertyId: 24,
        images: this.IntroImg,
        language: this.$i18n.locale,
      };

      const callback = (a) => {
        this.successModal = true;
        this.IntroImg = a.images;
      };

      const errorCallback = (err) => {
        this.errorMessage = err;
        this.errorModal = true;
      };

      this.reqPostHotelDetail(body, callback, errorCallback);
    },
    //#endregion Request


    //#region Method
    /** Form: highlight if not valid */
    requiredFd() {
      this.$v.editFacility.$touch();
    },

    /** Form: button check modal */
    confirmM() {
      this.checkModal = true;
    },

    /** "-"排除した値をセット
     * @param key
    */
    removeHyphen(key) {
      const hyphenChar = "-";
      this.editFacility[key] = this.editFacility[key].split(hyphenChar).join('');
    },

    fetchFacility() {
      console.log(this.editFacility);
      return this.editFacility;
    },
    //#endregion Method
    newtest() {
      console.log("name" + this.editFacility.name)
      return this.editFacility.name
    },
  },

  /**
   * before page mounted
   * */
  beforeMount() {
    this.reqGetAllBranch();
    this.reqGetFacility();
  },
};
</script>

