<template>
  <div class="access_info edit_basic_options">
    <h5 class="title">{{ $t("yadoEdit.accessForm.title") }}</h5>
    <div class="edit_form">
      <CRow class="border-bottom border-top">
        <CCol md="3"><strong class="form_lable">{{
            $t("yadoEdit.accessForm.address")
          }}</strong></CCol>
        <CCol>
          <CInput
            v-model="editData.address"
            disabled
          />
        </CCol>
        <CCol>
          <CButton
            color="info"
            @click="onMoveMapToAddressClick()"
          ><i class="cil-sync"></i> {{$t("yadoEdit.accessForm.refreshCoorinate")}}</CButton>
        </CCol>
      </CRow>
      <CRow class="border-bottom">
        <CCol md="3"><strong class="form_lable">{{
            $t("yadoEdit.accessForm.tel")
          }}</strong></CCol>
        <CCol>
          <CInput
            v-model="editData.tel"
            disabled
          />
        </CCol>
      </CRow>
      <CRow class="border-bottom">
        <CCol md="3"><strong class="form_lable">{{
            $t("yadoEdit.accessForm.coordinate")
          }}</strong></CCol>
        <CCol>
          <CRow class="mb-2">
            <CCol md="2"><strong>{{ $t("yadoEdit.accessForm.latitude")}}:</strong></CCol>
            <CCol md="4">
              <CInput
                v-model.number="editData.latitude"
                @keyup="onLatKeyup()"
              />
            </CCol>
            <CCol md="2"><strong>{{ $t("yadoEdit.accessForm.longitude")}}:</strong></CCol>
            <CCol md="4">
              <CInput
                v-model.number="editData.longitude"
                @keyup="onLngKeyup()"
              />
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <CRow class="border-bottom">
        <CCol md="3"><strong class="form_lable">{{
            $t("yadoEdit.accessForm.gMaps")
          }}</strong></CCol>
        <CCol>
          <GmapMap
            :center="{ lat: editData.latitude, lng: editData.longitude }"
            :zoom="gmapMap.zoom"
            :options="gmapMap.options"
            ref="map"
            style="width: 500px; height: 300px"
            @dragend="onDragEnd"
          >
            <GmapInfoWindow
              :options="gmapInfoWindow.infoOptions"
              :position="gmapInfoWindow.infoWindowPos"
              :opened="gmapInfoWindow.infoWinOpen"
              @closeclick="gmapInfoWindow.infoWinOpen = false"
            >
              {{ editData.name }}<br>
              {{ editData.address }}<br>
            </GmapInfoWindow>
            <GmapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              :clickable="true"
              :draggable="true"
              @click="onMarkerClicked(m)"
            />
          </GmapMap>
        </CCol>
      </CRow>
      <CRow class="border-bottom">
        <CCol md="3"><strong class="form_lable">{{
            $t("yadoEdit.accessForm.guide")
          }}</strong></CCol>
        <CCol>
          <CTextarea
            v-model="editData.guide"
            rows="6"
          />
        </CCol>
      </CRow>
      <CRow class="border-bottom">
        <CCol md="3"><strong class="form_lable">{{
            $t("yadoEdit.accessForm.parkingInfo")
          }}</strong></CCol>
        <CCol>
          <CTextarea
            v-model="editData.parkingInfo"
            rows="6"
          />
        </CCol>
      </CRow>
      <CRow class="border-bottom">
        <CCol md="3"><strong class="form_lable">{{
            $t("yadoEdit.accessForm.pickupInfo")
          }}</strong></CCol>
        <CCol>
          <CTextarea
            v-model="editData.pickupInfo"
            rows="6"
          />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CButton
            color="success"
            @click="onSubmitClicked()"
          ><i class="cil-save"></i> {{$t('common.update')}}</CButton>
        </CCol>
      </CRow>
    </div>
    <!-- .tab_footer end  -->

    <!-- 更新完了ダイアログ -->
    <CModal
      color="success"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="successModal"
    >
      {{ $t("commonMessage.updateSuccessText") }}
      <template #header> {{ $t("commonMessage.updateSuccessHeader") }} </template>
      <template #footer>
        <CButton
          @click="successModal = false"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{$t('common.close')}}
        </CButton>
      </template>
    </CModal>

    <!-- エラーダイアログ -->
    <CModal
      color="danger"
      closeOnBackdrop
      :centered="true"
      :show.sync="errorModal"
    >
      <p>{{ errorMessage }}</p>
      <template #header>
        <h4 class="modal-title">{{$t('common.error')}}</h4>
      </template>
      <template #footer>
        <CButton
          @click="errorModal = false"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{$t('common.close')}}
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import PROPERTY from "@/mixins/property";

export default {
  mixins: [PROPERTY],
  computed: {
    /** マーカーリスト */
    markers() {
      const marker = {
        position: { lat: this.editData.latitude, lng: this.editData.longitude },
      };
      return [marker];
    },

    /**宿ID取得 */
    uid() {
      return this.$store.state.uniqueId;
    }
  },

  data() {
    return {
      /** 処理結果表示 */
      successModal: false,
      errorModal: false,
      errorMessage: "",
      /** 表示・編集データ */
      editData: {
        name: "",
        address: "",
        tel: "",
        latitude: 0,
        longitude: 0,
        guide: "",
        parkingInfo: "",
        pickupInfo: "",
      },
      /** APIから取得した宿情報 */
      facility: {},
      /** APIから取得した宿プロパティ */
      properties: [],
      /** GmapMap設定 */
      gmapMap: {
        zoom: 14,
        options: {
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
        },
      },
      /** GmapInfoWindow設定 */
      gmapInfoWindow: {
        infoOptions: {
          pixelOffset: {
            width: 0,
            height: -35,
          },
        },
        infoWindowPos: null,
        infoWinOpen: false,
      },
    };
  },

  methods: {
    //#region ----- event -----

    /** 住所に移動ボタンクリック */
    onMoveMapToAddressClick() {
      this.moveMapToAddress();
    },

    /** 地図ドラッグ終了 */
    onDragEnd() {
      this.setLatLng();
    },

    /** 緯度入力更新 */
    onLatKeyup() {
      this.moveCenter();
    },

    /** 経度入力更新 */
    onLngKeyup() {
      this.moveCenter();
    },

    /** マーカークリック */
    onMarkerClicked(marker) {
      this.toggleMarkerBubble(marker);
    },

    /** 保存 */
    onSubmitClicked() {
      this.reqPutFacility();
      // this.reqPutTextContents();
    },

    //#endregion ----- event -----

    //#region ----- method -----

    /** 地図表示位置で緯度経度を更新 */
    setLatLng() {
      const center = this.$refs.map.$mapObject.getCenter();
      this.editData.latitude = center.lat();
      this.editData.longitude = center.lng();
    },

    /** 緯度経度で地図表示位置を更新 */
    moveCenter() {
      this.$refs.map.$mapObject.setCenter({
        lat: this.editData.latitude,
        lng: this.editData.longitude,
      });
    },

    /** マーカー情報表示を切替 */
    toggleMarkerBubble(marker) {
      this.gmapInfoWindow.infoWindowPos = marker.position;
      this.gmapInfoWindow.infoWinOpen = true;
    },

    /** 住所に移動 */
    moveMapToAddress() {
      const address1 = this.facility.address1;
      const address2 = this.facility.address2;

      // geocoder api へのリクエスト用アドレスデータ
      const addressObj = {
        address_line_1: address1,
        address_line_2: address2,
        city: "",
        state: "",
        zip_code: "",
        country: "",
      };

      // 住所から緯度経度を取得
      this.$geocoder.send(addressObj, (response) => {
        if (response.results.length) {
          this.editData.latitude = response.results[0].geometry.location.lat;
          this.editData.longitude = response.results[0].geometry.location.lng;
        }
      });
    },

    /**宿情報更新 */
    reqPutFacility(){

      const body = {
        latitude: this.editData.latitude,
        longitude: this.editData.longitude,
      };

      const callback = () =>{
        this.reqPutTextContents();
      }

      const errorCallback = (err) =>{
        this.errorMessage = err;
        this.errorModal = true;
      }

      this.reqPutHotel(body,callback,errorCallback);
    },

    /**テキストエリアの情報を更新 */
    reqPutTextContents(){

      // TODO: 【検討中】言語指定は多言語表示と別で行う？
      const language = this.$i18n.locale;
      const body = [
        {
          propertyId: this.PROPERTY.GUIDE_ID,
          description: this.editData.guide,
          language: language,
        },
        {
          propertyId: this.PROPERTY.PARKING_INFO_ID,
          description: this.editData.parkingInfo,
          language: language,
        },
        {
          propertyId: this.PROPERTY.PICKUP_INFO_ID,
          description: this.editData.pickupInfo,
          language: language,
        },
      ];

      const callback = () =>{
        this.successModal = true;
      }

      const errorCallback = (err) =>{
        this.errorMessage = err;
        this.errorModal = true;
      }

      this.reqPostHotelOption(body,callback,errorCallback);
    },


    /**宿情報取得 */
    reqGetFacility(){
      const callback = (a) =>{
        this.facility = a;
        this.refreshLocation();
      }

      this.reqGetHotel(callback);
    },

    /**宿のプロパティ取得 */
    reqGetAllFacilityProperties(){
      const callback = (a) =>{
        this.properties = a.properties;
        this.refreshProperty();
      }

      this.reqGetHotelOptionAll(callback);
    },

    /**
     * 宿ロケーションをAPIから取得したデータに更新
     */
    refreshLocation() {
      this.editData.name = this.facility.name;
      this.editData.address = this.facility.address1 + this.facility.address2;
      this.editData.tel = this.facility.telephone;

      if (this.facility.latitude == null || this.facility.longitude == null) {
        // APIから取得した緯度経度がnullの場合、住所から緯度経度を取得
        this.moveMapToAddress();
        return;
      }

      this.editData.latitude = this.facility.latitude;
      this.editData.longitude = this.facility.longitude;
    },

    /**
     * 宿プロパティをAPIから取得したデータに更新
     */
    refreshProperty() {
      const getValue = (id) =>
        this.properties.find((a) => a.propertyId == id).description;

      this.editData.guide = getValue(this.PROPERTY.GUIDE_ID);
      this.editData.parkingInfo = getValue(this.PROPERTY.PARKING_INFO_ID);
      this.editData.pickupInfo = getValue(this.PROPERTY.PICKUP_INFO_ID);
    },

    //#endregion ----- method -----
  },

  beforeMount() {
    this.reqGetFacility();
    this.reqGetAllFacilityProperties();
  },
};
</script>