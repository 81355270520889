<template>
  <div class="operator registration-page">
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <strong class="card-title">{{ $t("facilityEditPage.pageTitle") }}</strong>
            <CButton
              size="sm"
              color="dark"
              class="preview-button"
              @click="goPreview()"
            ><span class="eye-icon">&#128065;</span> <span>{{$t('common.preview')}}</span></CButton>
          </CCardHeader>
          <CCardBody class="px-5">
            <CTabs
              class="edit_tab"
              fade
              :active-tab="$store.state.editActiveTab"
            >
              <CTab
                :title="$t('facilityEditPage.primaryInformation')"
                @click.native="activeTab(0)"
              >
                <CRow class="px-3">
                  <CCol md="10">
                    <EditProfile 
                      @updateProfile="refresh = $event"
                      @facility="facility = $event"
                     />
                    <!-- <EditProfile
                      @updateProfile="refresh = $event"
                      v-if="$store.state.editActiveTab==0"
                    /> -->
                  </CCol>
                </CRow>
              </CTab>
              <!-- end profile edit tab  -->
              <CTab
                :title="$t('facilityEditPage.referralInformation')"
                @click.native="activeTab(1)"
              >
                <CRow class="px-3">
                  <CCol>
                    <EditPickup v-if="$store.state.editActiveTab==1" />
                  </CCol>
                </CRow>
              </CTab>
              <!-- end text EditPickup edit tab  -->
              <CTab
                :title="$t('facilityEditPage.basicInformation')"
                @click.native="activeTab(2)"
              >
                <CRow class="px-3">
                  <CCol>
                    <EditBasic v-if="$store.state.editActiveTab==2" />
                  </CCol>
                </CRow>
              </CTab>
              <!-- end Basic info tab  -->
              <CTab
                :title="$t('facilityEditPage.mealInformation')"
                @click.native="activeTab(3)"
              >
                <CRow class="px-3">
                  <CCol>
                    <EditMeal v-if="$store.state.editActiveTab==3" />
                  </CCol>
                </CRow>
              </CTab>
              <!-- end EditMeal  tab  -->
              <CTab
                :title="$t('facilityEditPage.bathformation')"
                @click.native="activeTab(4)"
              >
                <CRow class="px-3">
                  <CCol>
                    <EditBath v-if="$store.state.editActiveTab==4" />
                  </CCol>
                </CRow>
              </CTab>
              <!-- end EditBath info tab  -->
              <CTab
                :title="$t('facilityEditPage.facilityformation')"
                @click.native="activeTab(5)"
              >
                <CRow class="px-3">
                  <CCol>
                    <EditFacility v-if="$store.state.editActiveTab==5" />
                  </CCol>
                </CRow>
              </CTab>
              <!-- end EditFacility info tab  -->
              <CTab
                :title="$t('facilityEditPage.serviceInformation')"
                @click.native="activeTab(6)"
              >
                <CRow class="px-3">
                  <CCol>
                    <EditService v-if="$store.state.editActiveTab==6" />
                  </CCol>
                </CRow>
              </CTab>
              <!-- end EditService edit tab  -->

              <CTab
                :title="$t('yadoEdit.accessForm.title')"
                @click.native="activeTab(7);"
              >
                <CRow class="px-3">
                  <CCol>
                    <EditAccess
                      :key="refresh"
                      v-if="$store.state.editActiveTab==7"
                    />
                  </CCol>
                </CRow>
              </CTab>
              <!-- end gallery by image url  -->

              <!-- #region facility tag relation -->
              <CTab
                :title="$t('facilityEditPage.facilityTag')"
                @click.native="activeTab(8);"
                v-if="isRole"
              >
                <CRow class="px-3">
                  <CCol>
                    <EditTag
                      :key="refresh"
                      v-if="$store.state.editActiveTab==8"
                    />
                  </CCol>
                </CRow>
              </CTab>
              <!-- #endregion facility tag relation -->
            </CTabs>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import EditProfile from "./components/EditProfile";
import EditBasic from "./components/EditBasic";
import EditService from "./components/EditService";
import EditPickup from "./components/EditPickup";
import EditAccess from "./components/EditAccess";
import EditMeal from "./components/EditMeal";
import EditBath from "./components/EditBath";
import EditFacility from "./components/EditFacility";
import EditTag from "./components/EditTag";
import HOTEL_STATUS from "@/mixins/property";

const ROLE = {
  ADMIN: "yado_operator_admin",
  STAFF: "yado_operator_staff",
};

export default {
  name: "FacilityEdit",

  mixins: [HOTEL_STATUS],

  components: {
    EditMeal,
    EditBath,
    EditFacility,

    EditProfile,
    EditBasic,
    EditService,
    EditPickup,
    EditAccess,
    EditTag,
  },

  data() {
    return {
      refresh: 0,
      facility: {},
    };
  },

  computed: {
    /** ロールの判定 */
    isRole() {
      // アカウントのロールリスト
      const roles = this.$keycloak.realmAccess.roles;
      // adminかstaff権限を持っているときtrueそれ以外はfalse
      return roles.includes(ROLE.ADMIN) || roles.includes(ROLE.STAFF);
    },

    uid() {
      return this.$store.state.uniqueId;
    },
  },
  methods: {
    /**
     * save tab no for page reload
     */
    activeTab(TabNo) {
      this.$store.commit("set", ["editActiveTab", TabNo]);
    },

    goPreview() {
      const published = (this.facility.status & this.HOTEL_STATUS.FLAG_CONTENT_VISIBLE) !== 0;

      this.debugLog("facility");
      this.debugLog(this.facility);
      this.debugLog("published");
      this.debugLog(published);

      const location = published
        ? {
          name: "FacilityDetail", params: { uniqueId: this.uid },
        }
        : {
          name: "FacilityDetail", params: { uniqueId: this.uid },
          query: { preview: `${this.facility.telephone}${this.facility.zipcode}` },
        };

      let routeData = this.$router.resolve(location);
      window.open(routeData.href, "_blank");
    },
  },

  /**
   * before page mounted
   * */
  beforeMount() { },
};
</script>
