<template>
  <div class="edit_basic_options">
    <h4 class="sub-title">{{$t('facilityEditPage.facilityformation')}}</h4>

    <div
      class="edit_form"
      v-if="isLoad"
    >

      <!-- <code>{{allPropertiesView}}</code> -->
      <template v-for="property in allPropertiesView">
        <CRow
          class="border-bottom"
          :key="property.id"
        >
          <CCol md="3"><strong class="form_lable">{{ property.name }}</strong>
          </CCol>
          <CCol v-if="property.id !== PROPERTYLIST.totalRooms">
            <!-- <ServiceOptionModal
            :propertyItem="property"
            @property-status="property.status = $event"
            @property-description="property.description = $event"
            :key="property.status"
          /> -->
            <CTextarea
              rows="3"
              v-model="property.description"
            />
            <template v-if="[45].includes(property.id)">
              例）バリアフリー<br />
              例）当館にはエレベーターがございません。ご希望の方は1階のお部屋をご用意致します。
            </template>
          </CCol>
          <CCol v-if="property.id == PROPERTYLIST.totalRooms">
            <ul class="ul-line-block">
              <li>
                総客室数
              </li>
              <li>
                <CInput
                  type="number"
                  class="w-1"
                  :value.sync="property.description"
                />
              </li>
              <li>室</li>
            </ul>
          </CCol>
        </CRow>
      </template>

      <!-- #region 総客室数  -->
      <!-- TODO: API側でプロパティ追加されたらv-forの中に移す -->
      <!-- <CRow class="border-bottom">
        <CCol md="3"><strong>総客室数</strong></CCol>
        <CCol>
          <ul class="ul-line-block">
            <li>
              総客室数
            </li>
            <li>
              <CInput
                type="number"
                class="w-1"
                :value.sync="a"
              />
            </li>
            <li>室</li>
          </ul>
        </CCol>
      </CRow> -->
      <!-- #endregion 総客室数  -->

      <!-- onsen options  -->
      <div class="tab_footer">
        <CButton
          class="mt-3"
          color="success"
          @click="reqPostProperties()"
        ><i class="cil-save"></i> {{
        $t("common.update")
      }}</CButton>
      </div>
      <!-- .tab_footer end  -->
    </div>
    <!-- edit_form end -->

    <CModal
      color="success"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="successModal"
    >
      {{ $t("commonMessage.updateSuccessText") }}
      <template #header> {{ $t("commonMessage.updateSuccessHeader") }} </template>
      <template #footer>
        <CButton
          @click="successModal = false"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{$t('common.close')}}
        </CButton>
      </template>
    </CModal>
    <CModal
      color="danger"
      closeOnBackdrop
      :centered="true"
      :show.sync="errorModal"
    >
      <p>{{ errorMessage }}</p>
      <template #header>
        <h4 class="modal-title">{{$t('common.error')}}</h4>
      </template>
      <template #footer>
        <CButton
          @click="errorModal = false"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{$t('common.close')}}
        </CButton>
      </template>
    </CModal>
  </div>
  <!-- edit_options -->
</template>

<script>
import PROPERTYLIST from "@/mixins/property";

// import i18n from "../i18n";
// import ServiceOptionModal from "@/components/ServiceOptionModal.vue";

export default {
  mixins: [PROPERTYLIST],
  name: "EditBasicOption",
  components: {
    // ServiceOptionModal,
  },
  data() {
    return {
      isLoad: false,
      successModal: false,
      errorModal: false,
      errorMessage: "",
      allProperties: [],
      allPropertiesView: [
        {
          id: "",
          name: "",
          description: "",
          status: "",
        },
      ],
    };
  },
  computed: {
    /**宿ID取得 */
    uid() {
      return this.$store.state.uniqueId;
    },
  },
  methods: {
    /**全てのプロパティ取得 */
    reqGetAllProperties() {
      const callback = (a) => {
        this.allProperties = [];
        this.isLoad = true;
        const allList = a.properties;

        // only below selected properties are been shown
        const selectedList = [
          this.PROPERTYLIST.poolInformation,
          this.PROPERTYLIST.bedRockBath,
          this.PROPERTYLIST.massage,
          this.PROPERTYLIST.fitnessGym,
          this.PROPERTYLIST.gymnasium,
          this.PROPERTYLIST.ground,
          this.PROPERTYLIST.dogRun,
          this.PROPERTYLIST.aboutFacility,
          this.PROPERTYLIST.totalRooms,
        ];

        allList.forEach((a) => {
          selectedList.forEach((l, k) => {
            if (a.id == l) {
              a.viewOrder = k;
              this.allProperties.push(a);
            }
          });
        });

        this.allProperties.sort((a, b) => a.viewOrder - b.viewOrder);
        this.reqGetAllSelectedProperties();
      };

      this.reqGetPropertyAll(callback);
    },

    /**宿のプロパティ取得 */
    reqGetAllSelectedProperties() {
      const callback = (a) => {
        const getNewD = a.properties;
        // match data with all data
        this.allProperties.forEach((e) => {
          e.description = "";
          getNewD.forEach((a) => {
            if (a.propertyId == e.id) {
              e.pId = a.propertyId;
              e.description = a.description;
              e.status = a.status;
            }
          });
        });
        this.allPropertiesView = this.allProperties;
      };

      this.reqGetHotelOptionAll(callback);
    },

    /**宿のプロパティ登録 */
    reqPostProperties() {
      const body = [];
      this.allPropertiesView.filter((e) => {
        if ((e.description && e.description.length >= 1) || e.pId) {
          body.push({
            propertyId: e.id,
            description: e.description,
            status: e.status ? e.status : 0,
            language: this.$i18n.locale,
          });
        }
      });

      const callback = () => {
        this.reqGetAllProperties();
        this.successModal = true;
      };

      const errorCallback = (err) => {
        this.errorMessage = err;
        this.errorModal = true;
      };

      this.reqPostHotelOption(body, callback, errorCallback);
    },
  },
  beforeMount() {
    this.reqGetAllProperties();
  },
};
</script>
<style lang="scss">
.form-group {
  margin: 0;
}
.edit_basic_options {
  .edit_form {
    .row {
      .col,
      .col-md-3 {
        padding: 0.5rem 0.4rem;
        display: inline-block;
      }
    }
    .list-group-item {
      .col-md-3,
      .col {
        padding: 0 0.4rem;
        display: inline-block;
      }
    }
  }
}
</style>