<template>
  <div class="edit_basic_options">
    <h4 class="sub-title">{{$t('facilityEditPage.basicInformation')}}</h4>

    <div
      class="edit_form"
      v-if="isLoad"
    >

      <!-- <code>{{allPropertiesView}}</code> -->
      <CRow
        class="border-bottom"
        v-for="property in allPropertiesView"
        :key="property.id"
      >
        <CCol md="3"><strong class="form_lable">{{ property.name }}</strong>
        </CCol>
        <CCol>
          <!-- #region 現地精算で利用可能な決済方法 -->
          <template v-if="[7].includes(property.id)">
            <template v-for="type in ['credit', 'ec', 'qr', 'other']">
              <strong :key="'labal' + type">{{ type | paymentTypeLabel }}</strong>
              <br :key="'br' + type" />
              <CInputCheckbox
                class="mx-1"
                v-for="(payment, n) in payment[type]"
                :key="type + n + payment"
                :label="payment.label"
                :value="payment.value"
                custom
                inline
                :checked.sync="payment.enabled"
              />
              <CTextarea
                rows="3"
                v-model="paymentMethod[type].text"
                :key="'textarea' + type"
              />
            </template>
          </template>
          <!-- #endregion 現地精算で利用可能な決済方法 -->
          <template v-else-if="[42].includes(property.id)">
            <!-- チェックイン時間 -->
            <ul class="ul-line-block">
              <li>チェックイン開始時刻：</li>
              <li>
                <vue-timepicker
                  class="mt-1"
                  :minute-interval="30"
                  :placeholder="$t('common.timePicker')"
                  v-model="checkIn.begin"
                  hour-label="時"
                  minute-label="分"
                  format="HH:mm"
                  auto-scroll
                ></vue-timepicker>
              </li>
            </ul>
            <ul class="ul-line-block">
              <li>最終チェックイン時刻：</li>
              <li>
                <vue-timepicker
                  class="mt-1"
                  :minute-interval="30"
                  :placeholder="$t('common.timePicker')"
                  v-model="checkIn.end"
                  hour-label="時"
                  minute-label="分"
                  format="HH:mm"
                  auto-scroll
                ></vue-timepicker>
              </li>
            </ul>
            <CTextarea
              rows="3"
              v-model="checkIn.text"
            />
          </template>
          <template v-else-if="[43].includes(property.id)">
            <!-- チェックアウト時間 -->
            <ul class="ul-line-block">
              <li>チェックアウト時刻：</li>
              <li>
                <vue-timepicker
                  class="mt-1"
                  :minute-interval="30"
                  :placeholder="$t('common.timePicker')"
                  v-model="checkOut.time"
                  hour-label="時"
                  minute-label="分"
                  format="HH:mm"
                  auto-scroll
                ></vue-timepicker>
              </li>
            </ul>
            <CTextarea
              rows="3"
              v-model="checkOut.text"
            />
          </template>
          <template v-else>
            <CTextarea
              rows="3"
              v-model="property.description"
            />
          </template>
          <template v-if="[6].includes(property.id)">
            <!-- 入湯税・ホテル税 -->
            例）入湯税150円が別途かかります。
          </template>
          <template v-if="[8].includes(property.id)">
            <!-- ペット -->
            例）受入不可<br />
            例）小型犬のみ受入可
          </template>
          <template v-if="[9].includes(property.id)">
            <!-- お子様 -->
            例）小学生以上のお子様のみ受入可能<br />
            例）幼児のお子様は夕食がお子様プレートになります。
          </template>
        </CCol>
      </CRow>
      <!-- onsen options  -->
      <div class="tab_footer">
        <CButton
          class="mt-3"
          color="success"
          @click="reqPostProperties()"
        ><i class="cil-save"></i> {{
        $t("common.update")
      }}</CButton>
      </div>
      <!-- .tab_footer end  -->
    </div>
    <!-- edit_form end -->

    <CModal
      color="success"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="successModal"
    >
      {{ $t("commonMessage.updateSuccessText") }}
      <template #header> {{ $t("commonMessage.updateSuccessHeader") }} </template>
      <template #footer>
        <CButton
          @click="successModal = false"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{$t('common.close')}}
        </CButton>
      </template>
    </CModal>
    <CModal
      color="danger"
      closeOnBackdrop
      :centered="true"
      :show.sync="errorModal"
    >
      <p>{{ errorMessage }}</p>
      <template #header>
        <h4 class="modal-title">{{$t('common.error')}}</h4>
      </template>
      <template #footer>
        <CButton
          @click="errorModal = false"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{$t('common.close')}}
        </CButton>
      </template>
    </CModal>
  </div>
  <!-- edit_options -->
</template>

<script>
// import i18n from "../i18n";
// import ServiceOptionModal from "@/components/ServiceOptionModal.vue";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

import PROPERTYLIST from "@/mixins/property";
import PAYMENT from "@/mixins/property";

export default {
  mixins: [PROPERTYLIST, PAYMENT],

  name: "EditBasic",

  components: {
    VueTimepicker,
    // ServiceOptionModal,
  },

  data() {
    return {
      isLoad: false,
      successModal: false,
      errorModal: false,
      errorMessage: "",
      allProperties: [],
      allPropertiesView: [
        {
          id: "",
          name: "",
          description: "",
          status: "",
        },
      ],

      // 現地精算で利用可能な決済方法
      // paymentMethod: {
      //   text: "",
      //   list: [],
      // },

      paymentMethod: {
        credit: {
          text: "",
          list: [],
        },
        qr: {
          text: "",
          list: [],
        },
        ec: {
          text: "",
          list: [],
        },
        other: {
          text: "",
          list: [],
        },
      },

      // NOTE: src\main\webapp\src\components\PropertyView.vue と値を同じにすること
      payment:
      {
        // クレジットカード
        credit: [
          { label: 'JCB', value: 0, enabled: false, },
          { label: 'VISA', value: 1, enabled: false, },
          { label: 'マスター', value: 2, enabled: false, },
          { label: 'AMEX', value: 3, enabled: false, },
          { label: 'UC', value: 4, enabled: false, },
          { label: 'DC', value: 5, enabled: false, },
          { label: 'NICOS', value: 6, enabled: false, },
          { label: 'ダイナース', value: 7, enabled: false, },
          { label: 'UFJ', value: 8, enabled: false, },
          { label: 'セゾン', value: 9, enabled: false, },
          { label: 'デビットカード', value: 10, enabled: false, },
          { label: '他クレジットカード', value: 11, enabled: false, },
        ],
        // 電子マネー
        ec: [
          { label: 'iD', value: 0, enabled: false, },
          { label: 'QUICPay', value: 1, enabled: false, },
          { label: 'Suica', value: 2, enabled: false, },
          { label: 'PASMO', value: 6, enabled: false, },
          { label: 'toICa', value: 7, enabled: false, },
          { label: 'ICOCA', value: 8, enabled: false, },
          { label: 'SUGOCA', value: 9, enabled: false, },
          // { label: 'WAON', value: 3, enabled: false, }, // 削除
          // { label: 'nanaco', value: 4, enabled: false, }, // 削除
          { label: '楽天Edy', value: 5, enabled: false, },
          { label: '他電子マネー決済', value: 99, enabled: false, },
        ],
        // QRコード決済
        qr: [
          { label: 'PayPay', value: 0, enabled: false, },
          { label: 'LINEPay', value: 1, enabled: false, },
          { label: '楽天Pay', value: 2, enabled: false, },
          // { label: 'Airペイ', value: 3, enabled: false, }, // NOTE: 削除
          // { label: 'AirペイQR', value: 4, enabled: false, }, // NOTE: 削除
          { label: 'd払い', value: 5, enabled: false, },
          { label: 'au PAY', value: 6, enabled: false, },
          { label: 'J-coin Pay', value: 7, enabled: false, },
          { label: 'Alipey+', value: 8, enabled: false, },
          { label: 'WeChat Pay', value: 9, enabled: false, },
          { label: 'UnionPay', value: 10, enabled: false, },
          { label: '他QRコード決済', value: 99, enabled: false, },
        ],
        other: [],
      },

      // チェックイン時間
      checkIn: {
        text: "",
        begin: "",
        end: "",
      },

      // チェックアウト時間
      checkOut: {
        text: "",
        time: "",
      },

    };
  },

  computed: {
    /**宿ID取得 */
    uid() {
      return this.$store.state.uniqueId;
    },
  },

  methods: {
    /**全てのプロパティ取得 */
    reqGetAllProperties() {
      const callback = (a) => {
        this.allProperties = [];
        this.isLoad = true;
        const allList = a.properties;

        // only below selected properties are been shown
        const selectedList = [
          this.PROPERTYLIST.bathAndHotelTax,
          this.PROPERTYLIST.paymentMethod,
          this.PROPERTYLIST.checkInTime,
          this.PROPERTYLIST.checkOutTime,
          this.PROPERTYLIST.pets,
          this.PROPERTYLIST.children,
        ];

        allList.forEach((a) => {
          selectedList.forEach((l, k) => {
            if (a.id == l) {
              a.viewOrder = k;
              this.allProperties.push(a);
            }
          });
        });

        this.allProperties.sort((a, b) => a.viewOrder - b.viewOrder);
        this.reqGetAllSelectedProperties();
      };

      this.reqGetPropertyAll(callback);
    },

    /**宿のプロパティ取得 */
    reqGetAllSelectedProperties() {
      const callback = (data) => {
        // match data with all data
        this.allProperties.forEach((e) => {
          e.description = "";
          data.properties
            .filter(a => a.propertyId === e.id)
            .forEach((a) => {
              e.pId = a.propertyId;
              e.description = a.description;
              e.status = a.status;
            });
        });

        this.allPropertiesView = this.allProperties;

        // 現地精算で利用可能な決済方法
        const paymentMethod = this.allPropertiesView.find(e => e.pId === 7);
        if (paymentMethod && paymentMethod.description) {
          this.paymentMethod = JSON.parse(paymentMethod.description);
        }

        ['credit', 'ec', 'qr', 'other'].forEach(type => {
          this.debugLog(this.paymentMethod[type]);
          // this.debugLog(this.paymentMethod[type]);

          if (!this.paymentMethod[type]) {
            // フォーマット誤りはデータ初期化
            this.paymentMethod[type] = {
              text: "",
              list: [],
            }
          }

          this.payment[type]
            .filter(b => this.paymentMethod[type]?.list?.includes(b.value))
            .forEach(b => b.enabled = true);
        });
        this.debugLog(this.payment);

        // チェックイン時間
        const checkIn = this.allPropertiesView.find(e => e.pId === 42);
        if (checkIn && checkIn.description) {
          this.checkIn = JSON.parse(checkIn.description);
        }
        this.debugLog(this.checkIn);

        // チェックアウト時間
        const checkOut = this.allPropertiesView.find(e => e.pId === 43);
        if (checkOut && checkOut.description) {
          this.checkOut = JSON.parse(checkOut.description);
        }
        this.debugLog(this.checkOut);
      };

      this.reqGetHotelOptionAll(callback);
    },

    /**宿のプロパティ登録 */
    reqPostProperties() {

      // 現地精算で利用可能な決済方法のリスト
      ['credit', 'ec', 'qr', 'other'].forEach(type =>
        this.paymentMethod[type].list = this.payment[type]
          .filter(a => a.enabled)
          .map(a => a.value));

      // 特殊項目を対応
      this.allPropertiesView.forEach(a => {
        switch (a.id) {
          case 7: // 現地精算で利用可能な決済方法
            a.description =
              (this.paymentMethod.credit.list?.length > 0 || this.paymentMethod.credit?.text)
                || (this.paymentMethod.qr.list?.length > 0 || this.paymentMethod.qr?.text)
                || (this.paymentMethod.ec.list?.length > 0 || this.paymentMethod.ec?.text)
                || (this.paymentMethod.other?.text)
                ? JSON.stringify(this.paymentMethod) : null;
            break;
          case 42: // チェックイン時間
            a.description = (this.checkIn.begin || this.checkIn.end || this.checkIn.text) ? JSON.stringify(this.checkIn) : null;
            break;
          case 43: // チェックアウト時間
            a.description = (this.checkOut.time || this.checkOut.text) ? JSON.stringify(this.checkOut) : null;
            break;
          default:
            break;
        }
      });

      // リクエストボディ
      const body =
        this.allPropertiesView
          .filter(a => (a.description && a.description.length >= 1) || a.pId)
          .map(a => {
            return {
              propertyId: a.id,
              description: a.description,
              status: a.status ? a.status : 0,
              language: this.$i18n.locale,
            }
          });

      const callback = () => {
        this.reqGetAllProperties();
        this.successModal = true;
      };

      const errorCallback = (err) => {
        this.errorMessage = err;
        this.errorModal = true;
      };

      this.reqPostHotelOption(body, callback, errorCallback);
    },
  },

  filters: {
    paymentTypeLabel(type) {
      switch (type) {
        case 'credit': return "クレジットカード";
        case 'qr': return "QRコード決済";
        case 'ec': return "電子マネー決済";
        case 'other': return "その他決済について";
        default:
          break;
      }
    }
  },

  beforeMount() {
    this.reqGetAllProperties();
  },
};
</script>
<style lang="scss">
.form-group {
  margin: 0;
}
.edit_basic_options {
  .edit_form {
    .row {
      .col,
      .col-md-3 {
        padding: 0.5rem 0.4rem;
        display: inline-block;
      }
    }
    .list-group-item {
      .col-md-3,
      .col {
        padding: 0 0.4rem;
        display: inline-block;
      }
    }
  }
}
</style>